import { FormControl, FormGroup } from '@angular/forms';
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import * as moment from 'moment';
import { TemplateGroupServices } from '../../views/template/services/templategroup.service';
import { MasterServices } from '../_services/master.service';

export class CustomValidator {
  public static required(control: AbstractControl): { [key: string]: any } | null {
    if (control.value == null || control.value == '' || control.value == 0) {
      return { 'required': true }
    }
    else {
      return null
    }
  }

  public static requiredAllowZero(control: AbstractControl): { [key: string]: any } | null {
    if (control.value == null || control.value == '' ) {
      return { 'required': true }
    }
    else {
      return null
    }
  }

  public static ValidOTP(control: AbstractControl): { [key: string]: any } | null {
    return { 'custom': true }
  }

  public static IsDuplicate(list: any[], Key: string, Id: any): any {
    return (control: AbstractControl): any => {
      if (!control.value) {
        return null;
      }
      const value = (list ?? []).filter(o => o[Id.Key] != Id.Value).filter(o => o[Key] == control.value) ?? [];
      return value.length > 0 ? { duplicate: true, value: value[0] } : null;
    }
  }
}

export class CustomTxtValidator {
  public static required(control: AbstractControl): { [key: string]: any } | null {
    if (control.value == null || control.value == '' || (control.value).trim().length === 0) {
      return { 'required': true }
    }
    else {
      return null
    }
  }
}

export class AlphabetRegEx {
  public static pattern = "^[a-zA-Z\\s]+$";
}

export class AutoSequenceRegEx {
  public static pattern = '^[a-zA-Z-/.,+=_}{?[\\]()\\\\]+$';
}

export class NumbersRegEx {
  public static pattern = "^[0-9]*$";
}

export class DateRegEx {
  public static pattern = /^\d{4}\/\d{2}\/\d{2}$/;
}

export class Decimal2RegEx {
  public static pattern = "^[0-9]+(.[0-9]{0,2})?$";
}

export class AlphabetNumbersRegEx {
  public static pattern = "^[a-zA-Z0-9\\s]+$";
}

export class ValidEmail {
  public static pattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[A-Za-z]{2,9}$";
}

export class ValidURL {
  public static pattern = "((http|https)://)(www.)?" +
    "[a-zA-Z0-9@:%_\\+~#?&//=]{2,256}" +
    "((\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+~#?&//=]*))|(\\b([-a-zA-Z0-9@:%_\\+~#?&//=]*)))";
}

export class ValidPhone {
  //public static pattern = "^[0-9]{8,15}$";
  public static pattern = "^[0-9]*$"
}

export class validSequenceCode
{
  public static pattern = /^([a-zA-Z0-9~!@#$%^&*()_+= ,;|:?/"'.]+)$/ 
}

export class ValidAccountCode {
  public static pattern = "^[^!@#$%^&*()`~+=\{\}\[\]\\:;\"'<>?,|]*$"
}

export class ValidLimitedSpecialChar {
  //Pls dont touch if you add or remove (implemented for Custom settings fieldName)
  public static pattern = "^[a-zA-Z0-9#@ /]*?$"
  public static patternOtherLanguage = /^[a-zA-Z0-9\p{L}\p{M}#@\/ ]*?$/u

}

export function EmailorPhoneValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const isValid = /^(([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,9},?)|([0-9]*))$/.test(value);
    return !isValid ? { custom: true } : null;
  }
}

export function ValidMultipleEmail(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const isValid = /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,9},?)+$/.test(value);
    return !isValid ? { custom: true } : null;
  }
}


export function ValidMultipleEmailWithSemicolonComma(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const valmail = control.value.replaceAll(new RegExp(';', 'g'), ',');//replace(";", ",");
    let emails = valmail.split(",").map(e => e.trim());
    emails = emails.filter(res => res != '');
    const forbidden = emails.filter(email => !(new RegExp(ValidEmail.pattern).test(email)));
    return forbidden.length > 0 ? { custom: true } : null;
  }
}

export class DateValidator {
  static dateVaidator(AC: AbstractControl) {
    const value = AC.value;
    if (!value) {
      return null;
    }

    if (AC && AC.value && !moment(AC.value, 'YYYY/MM/DD', true).isValid()) {
      return { 'dateValidator': true };
    }

    return null;
  }

}

export function tempNameExists(controlName: string, type: string, master: MasterServices, isnew: string, templateName: String) {
  return async (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    if (control.value == null || control.value == '' || (control.value).trim().length === 0) {
      control.setErrors({ incorrect: true, msg: 'Template Name is required','invalid': true });
      formGroup.controls[controlName].setErrors({ incorrect: true, msg: 'Template Name already exists','invalid': true });
    }
    else {
      let namelist: any = await master.GetTemplateThemeList(type);
      if (templateName == '') {
        templateName = control.value;
        if (isnew == 'false') {
          namelist = namelist.filter(item => item.description == templateName);
        }
      }
      else if(templateName == control.value){
        control.setErrors(null);
        return null;
      }
      
      let template = namelist.filter(item => item.description == control.value);
      if (template.length > 0) {
        if (control.value == template[0].description) {
          control.setErrors({ incorrect: true, msg: 'Template Name already exists','invalid': true });
          formGroup.controls[controlName].setErrors({ incorrect: true, msg: 'Template Name already exists','invalid': true });          
        }
        else {
          control.setErrors(null);
          return null;
        }
      }
      else {
        control.setErrors(null);
        return null;
      }
    }
  }
}
