export enum PrintType {
    HTML = 1,
    PDF = 2
}

export enum PaperSize {
    A5 = 1,
    A4 = 2,
    Letter = 3
}

export enum Orientation {
    Portrait = 1,
    Landscape = 2
}

export enum GroupNames{
    PurchaseOrder=1,
    Bills=2,
    Quotes=3,
    Invoice=4,
    CreditNote=5,
    Statement=6,
    Receipt=7,
    DeliveryOrder=8,
    Voucher=9,
    InvoiceReceipt=10,
    BillVoucher=11,
    SalesOrder=13,
    PayableStatment=12,
    PurchaseCreditNote=14,
    DebitNote=15,
    PurchaseDebitNote=16,
    JournalEntry = 17,
    ReceiveOrder=18,
}