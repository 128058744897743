<div bsModal #showEditAccount="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="showEditAccountLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog" role="document">
    <ng-container *ngIf="isEditForm">
      <form class="form-horizontal needs-validation" [formGroup]="editAccountForm" (ngSubmit)="submitAccountForm()">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">{{'Common.Edit' | translate}} - {{editedAccount.AccountName}}</h4>
            <button type="button" class="close" (click)="cancelAccountForm()">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <label for="AccountName">{{'SharedModule.Account Name' | translate}} <span class="text-danger">*</span></label>
                <input type="text" formControlName="AccountName" class="form-control" maxlength="100"
                  [ngClass]="{ 'is-invalid': submitted && f.AccountName.errors }" xssDirective />
                <div *ngIf="submitted && f.AccountName.errors" class="invalid-feedback">
                  <div *ngIf="f.AccountName.errors?.required">{{'SharedModule.Please provide an Account Name' | translate}}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label for="BankAccountNo">{{'SharedModule.Account No' | translate}} <span class="text-danger">*</span></label>
                <ng-container *ngIf="!accedit">
                  <br>
                  <span>{{editedAccount.maskBankAccountNo}}</span>&nbsp;&nbsp;&nbsp;
                  <a class="btn-link text-primary" style="cursor: pointer;" (click)="accedit = true">{{'Common.Edit' | translate}}</a>
                </ng-container>
                <input type="text" formControlName="BankAccountNo" class="form-control" maxlength="50" *ngIf="accedit"
                  [ngClass]="{ 'is-invalid': submitted && f.BankAccountNo.errors }" xssDirective />
                <div *ngIf="submitted && f.BankAccountNo.errors" class="invalid-feedback">
                  <div *ngIf="f.BankAccountNo.errors?.numberPattern">{{'SharedModule.Please provide proper Account Number' | translate}}</div>
                  <div *ngIf="f.BankAccountNo.errors?.required">{{'SharedModule.Please provide an Account No' | translate}}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label for="BankAccTypeID">{{'SharedModule.Account Type' | translate}} <span class="text-danger">*</span></label>
                <ng-select formControlName="BankAccTypeID" [clearable]="false" [clearSearchOnAdd]="true"
                  [ngClass]="{ 'is-invalid': submitted && f.BankAccTypeID.errors }" [items]="accountTypes"
                  bindValue="BankAccTypeID" bindLabel="BankAccTypeName">
                </ng-select>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label for="AccountCode">{{'AccountingModule.ChartOfAccounts.Account code' | translate}}</label>
                <input type="text" formControlName="AccountCode" class="form-control" (blur)="accountCodeExists();"
                  maxlength="10" [ngClass]="{ 'is-invalid': submitted && f.AccountCode.errors }" accountcode />
                <div *ngIf="!f.AccountCode.errors?.pattern && accCodeExists.isShow">
                  <div *ngIf="accCodeExists.isExists==true" class="badge badge-pill badge-danger">
                    '{{accCodeExists.code}}' {{'SharedModule.is not available' | translate}}:
                    {{accCodeExists.code}} - {{accCodeExists.name}}
                  </div>
                  <div *ngIf="accCodeExists.isExists==false" class="badge badge-pill badge-success">
                    '{{accCodeExists.code}}' {{'SharedModule.is available' | translate}}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label for="Currency">{{'Common.Currency' | translate}}</label>
                <h6>{{editedAccount.CurrencyName}}</h6>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="mr-4 pr-2">
              <ng-container *ngIf="editedAccount.AutoFeedTypeId == 1">
                <!-- <button type="button" (click)="DeactivateAutoFeedAccount()" class="btn btn-primary mr-4"
                *userCanEdit="[true]">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                De-activate Direct Link
              </button> -->
                <a class="t-link" style="text-decoration: underline;" (click)="delinkAccount()">
                  {{'SharedModule.How to Delink OCBC Account ?' | translate}}
                </a>
              </ng-container>
            </div>
            <div class="row">
              <div class="col-12 text-right">
                <!-- <ng-container
                  *ngIf="(editedAccount.IsAuthennticatedtoGetAutoFeed && editedAccount.providerAccountId != undefined && editedAccount.providerAccountId != null) == true">
                  <button type="button" (click)="yodleeUpdate()" class="btn btn-primary mr-4" *userCanEdit="[true]">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Update Yodlee Details
                  </button>
                </ng-container> -->
                <!-- editedAccount.IsAuthennticatedtoGetAutoFeed == 1 means OCBC feed -->
                <!-- <ng-container *ngIf="editedAccount.AutoFeedTypeId==1 && editedAccount.IsDelinked">
                  <button type="button" (click)="ReactivateAutoFeedAccount()" class="btn btn-primary mr-4"
                    *userCanEdit="[true]">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Re-activate Direct Link
                  </button>
                </ng-container> -->





                <button type="submit" [disabled]="loading" class="btn btn-success" *userCanEdit="[true]">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  {{'Common.Save' | translate}}
                </button>
                <button type="button" (click)="cancelAccountForm()" class="btn btn-danger">{{'Common.Cancel' | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
</div>

<div bsModal #DelinkPopup="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="showEditAccountLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog lg" role="document">
    <ng-container>
      <form class="form-horizontal needs-validation">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">{{'SharedModule.Delink OCBC Account' | translate}}</h4>
            <button type="button" class="close" (click)="cancelDelinkPopup()">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            {{'SharedModule.To delink your OCBC account direct feed, Please login to OCBC velocity and delink from there.' | translate}}
          </div>
          <div class="modal-footer">
            <div class="">
              <button class="btn btn-success" (click)="cancelDelinkPopup()">{{'Common.Close' | translate}}</button>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
</div>
