import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from '../../../../environments/environment';
import { ValidPhone } from '../../../utilities/_directives/customvalidator.directive';
import { AlertMessage } from '../../../utilities/_helpers/alert.message';
import { LocalCacheServices } from '../../../utilities/_services/acclocalcache.service';
import { HttpServices } from '../../../utilities/_services/http.service';
import { MasterServices } from '../../../utilities/_services/master.service';
import { SubscriptionService } from '../../../utilities/_services/subscription.service';

@Component({
  selector: 'app-subscriptionsignup',
  templateUrl: './subscriptionsignup.component.html',
  styleUrls: ['./subscriptionsignup.component.scss']
})
export class SubscriptionsignupComponent implements OnInit {
  isSuccess:boolean = false;
  loading = false;
  showLoading:boolean = false;
  lstTimeZone:any;
  timeZoneID:number = 0;;
  timeZoneName:String = '';
  currency:String = '';
  currencyCode:String = '';
  dailingCode:string='';
  lstCountry:any;
  lstDailing:any;
  lstLOBtype:any;
  registerForm: FormGroup | any;
  OTPForm: FormGroup | any;
  fullName:string="";
  emailAddress:string="";
  submitted = false;
  financeEnd:string = "2022-12-31";
  selectedCountry:string = "";
  pageOption:number=0;
    //financeStart= "2022-01-01";
  isMobileVerified:boolean = false;
  OTPvalue:string="";
  OTPDuration:number=2;
  planType:number=1;
  phoneCountry:String = "";
  trialdays:number=0;
  apphostnames = ["localhost","stginfoaccwebapp.azurewebsites.net", "staging.infotech-accounting.com"];
  @ViewChild('showMobileOTP') public showMobileOTP: ModalDirective;
  subscriptionAPIURL : string = environment.subscriptionApiUrl;
    constructor(private formBuilder: FormBuilder,private master: MasterServices, public datepipe: DatePipe,
      private http: HttpServices,private subscriptionService: SubscriptionService,private alertMessage: AlertMessage,
      private route: ActivatedRoute, private router: Router,private localCache: LocalCacheServices) {
      this.loadTimeZone();

      if(this.route.snapshot.queryParams != undefined){
        this.route.queryParams.subscribe((params:any) => {
          if(Object.keys(params).length > 0){
            let paramObj:any = JSON.parse(atob(Object.keys(params)[0]));
            if(paramObj != null && paramObj != undefined){
              if(paramObj.hasOwnProperty("params")){
                this.planType = (paramObj.params.planType)?paramObj.params.planType:1;
                this.selectedCountry = paramObj.params.country;
                this.pageOption = paramObj.params.pageOption;

              }
              else{
                this.pageOption = paramObj.pageOption;
                this.fullName = paramObj.Name;
                this.isSuccess = paramObj.IsSuccess;
              }
              
            }
          }
        });
      }
     }

     GetTrialConfig(countryID:number)
     {
      return new Promise(resolve => {
      this.http.awaitGetservice(environment.subscriptionApiUrl + '/Subscription/GetTrialConfig?CountryID=' + countryID)
        .then((dt: any) => {
          this.trialdays=dt;
        });
    })
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
        companyName: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        phone:['',[Validators.required,Validators.pattern(ValidPhone.pattern)]],
        country:['',Validators.required],
        dialcountry:[''],
        //lobType:['',Validators.required],
        timeZone:[''],
        currencyName:['']
      });

      this.OTPForm = this.formBuilder.group({
        mobileOTP: ['', Validators.required]
      });
      this.getSignupMaster()
      .then((dt:any) => {
        if(dt != null && dt != undefined && dt != ""){

          let _countryID:string = "";
          this.lstCountry = JSON.parse(dt.filter(m => m.MasterType == "COUNTRY")[0]['MasterData']);
          this.lstDailing = JSON.parse(dt.filter(m => m.MasterType == "DIALCODE")[0]['MasterData']);
          _countryID = this.lstCountry.filter(m => m.CurrencyCode==this.selectedCountry)[0]['CountryID'];
         this.GetTrialConfig(Number(_countryID));

        if (_countryID != "") {
          this.ChangeDialing(_countryID.toString());
        }

        if (this.selectedCountry != "") {
          this.ChangeCountry(this.selectedCountry);
        }
      }
    });
  }

  ChangeDialing(evt: Event | string){
    let selDialcode: any;
    if (typeof evt == "string") {
      selDialcode = this.lstDailing.filter(m => m.CountryDialID==evt);
    }
    else {
      selDialcode = this.lstDailing.filter(m => m.CountryDialID == evt.target["value"]);
    }

    if (selDialcode.length > 0) {
      this.dailingCode = selDialcode[0].DailingCode;
      let _country:any = this.lstDailing.filter(m => m.CountryDialID==selDialcode[0].CountryDialID);
      if(_country.length > 0){
        this.phoneCountry = _country[0]['CountryISOCode']
      }
      else{
        let _defcountryID:any = this.lstCountry.filter(m => m.CurrencyCode==this.selectedCountry)[0]['CountryID'];
        this.phoneCountry = this.lstDailing.filter(m => m.CountryDialID==_defcountryID)[0]['CountryISOCode'];
      }
    }

    this.registerForm.patchValue({
      dialcountry:selDialcode[0].CountryDialID
    });
  }

  ChangeCountry(evt: Event | string) {
    let selCountry: any;
    if (typeof evt == "string") {
      selCountry = this.lstCountry.filter(m => m.CurrencyCode == evt);
    }
    else {
      selCountry = this.lstCountry.filter(m => m.CountryID == evt.target["value"]);
    }

    if (selCountry.length > 0) {
      this.timeZoneName = selCountry[0].TimeZoneName;
      this.timeZoneID = selCountry[0].TimeZoneID;
      this.currency = selCountry[0].CurrencyName + " (" + selCountry[0].CurrencyCode + ")";
      //this.currencyCode = selCountry[0].CurrencyCode;

      this.registerForm.patchValue({
        country:selCountry[0].CountryID,
        phone:''
      });
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  get m() { return this.OTPForm.controls; }

  onSubmit() {  
    this.loading = true;
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      this.loading = false;
      return;
    }
    else{

      if(!this.isphoneValid){
        this.submitted = false;
        this.loading = false;
        this.alertMessage.errorNotifier("Invalid mobile number", 0);
        return;
      }

      let isNonProd:boolean = false;
     // isNonProd = this.apphostnames.includes(window.location.hostname)
      this.GenerateOTP(isNonProd).then((dt:any) => {
        if(dt.ResponseData != "" && dt.ResponseData != null && dt.ResponseData != undefined){
          this.loading = false;
          this.localCache.setlocalStoragewithExpiry("SmsID",dt.ResponseData.SmsID,this.OTPDuration);
          this.showMobileOTP.show();
        }
        else{
          this.loading = false;
          this.alertMessage.errorNotifier(dt.StatusMessage, 0);
        }
      });
    }
  }


  SaveSubscriber(){
    this.showLoading = true;
    let payload = {
      "firstName": this.f.firstName.value,
      "lastName": this.f.lastName.value,
      "companyName": this.f.companyName.value,
      "email": this.f.email.value,
      "phone": this.dailingCode + '-' + this.f.phone.value,
      "countryID": this.f.country.value,
      "lobid": 1,
      "currencyCode": this.selectedCountry,
      "timeZoneID": this.timeZoneID,
      "financeYearEnd": this.financeEnd,
      "pageOption": this.pageOption,
      "planType":this.planType
    }
    this.http.postservice(this.subscriptionAPIURL + '/Subscription/SaveSubscriptionSignUp',payload).subscribe({
      next: (data:any) => {
        if(data != null && data != undefined){
          if(data.isSuccess == true && this.pageOption == 2){
            //this.CreateCustomer(payload,data.ResponseData);
            if (data["ResponseData"]) {
              let respData:any = data["ResponseData"];
              let _subParams:any = {'orgID':respData.organizations[0].OrgID,'countryID':this.f.country.value,'customercode':respData.CustomerCode,'pageOption':2,'isWithoutLogin':true,'planType':this.planType};
              this.router.navigate(['payment'],{queryParams: {subParams: btoa(JSON.stringify(_subParams))}});
            }
          }
          else  if(data.isSuccess == true && this.pageOption == 1){
            this.fullName =  this.f.firstName.value + " " + this.f.lastName.value;
            this.emailAddress =  this.f.email.value
            this.isSuccess = true;
            this.showLoading = false;
          }
          else{
            this.showLoading = false;
            this.isSuccess = false;
            this.alertMessage.errorNotifier(data.ResponseData["ResponseMsg"], 0);
          }
        }
      }
    });
  }

  CreateCustomer(objDt: any, subDt: any) {
    let Data: any = {};
    if ((objDt.phone as string).length > 0) {
      Data.ContactPhoneNo = objDt.phone; //ContactPhoneCode + '-' + objDt.ContactPhone;
      Data.AdminPhoneNo = objDt.phone;
    }
    else {
      Data.ContactPhoneNo = "";
    }

    Data.CustomerCode = 0;
    Data.CustomerName = (objDt.companyName != null && objDt.companyName != undefined && objDt.companyName != "") ? objDt.companyName : "";
    if (objDt.firstName != "" || objDt.lastName != "") {
      this.fullName = objDt.firstName + " " + objDt.lastName;
      Data.ContactPerson = this.fullName;
    }
    this.emailAddress = (objDt.email != null && objDt.email != undefined && objDt.email != "") ? objDt.email : "";
    Data.LOBTypeID = (objDt.lobid > 0) ? objDt.lobid : 0;
    Data.AdminEmail = this.emailAddress;
    Data.ContactEmail = this.emailAddress;
    Data.SubscriberID = (subDt.SubscriberID > 0) ? subDt.SubscriberID : 0;
    Data.Source = "CLOUD";
    Data.CountryID = objDt.countryID;
    Data.CurrencyCode = objDt.currencyCode;
    Data.FinancialYearEnd = this.datepipe.transform(objDt.financeYearEnd, 'dd-MM');
    Data.TimeZoneID = objDt.timeZoneID;
    Data.IsActive = true;
    Data.PageOption = this.pageOption;
    let expDt = new Date();
    expDt.setDate(expDt.getDate() + this.trialdays);
    let LStart = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    let LEnd = this.datepipe.transform(expDt, 'yyyy-MM-dd');


    Data.organizations = <any[]>[];
    Data.organizations.push({
      OrgID: 0,
      OrgName: objDt.companyName,
      CustomerCode: (subDt.CustomerCode == null || subDt.CustomerCode == undefined || subDt.CustomerCode == "") ? 0 : subDt.CustomerCode,
      LicenseTypeID: 2, // In Trial
      LicenseStart: LStart,
      LicenseEnd: LEnd,
      CountryID: objDt.countryID,
      CurrencyCode: objDt.currencyCode,
      LOBTypeID: objDt.lobid,
      FinanceYearEnd: this.datepipe.transform(objDt.financeYearEnd, 'dd-MM'),
      IsActive: true
    });
    this.subscriptionService.CreateORUpdateCustomer(Data).then(result => {
      if (result["isSuccess"] as boolean === true) {
        this.isSuccess = true;
        if (this.pageOption == 1) {
          this.createEnquiry(Data.SubscriberID);
        }
        this.onReset();
        if(this.pageOption == 2){
          if (result["ResponseData"]) {
            let respData:any = result["ResponseData"];
            let _subParams:any = {'orgID':respData.organizations[0].OrgID,'countryID':Data.CountryID,'customercode':respData.CustomerCode,'pageOption':2,'isWithoutLogin':true,'planType':this.planType};
            this.router.navigate(['payment'],{queryParams: {subParams: btoa(JSON.stringify(_subParams))}});
          }
        }
      }
      else {
        this.isSuccess = false;
        this.alertMessage.errorNotifier(result["StatusMessage"], 0);
        this.showLoading = false;
      }
    }).catch(err => {
      this.alertMessage.errorNotifier(JSON.stringify(err), 0);
      this.showLoading = false;
      this.isSuccess = false;
    });
  }

  onReset() {
      this.submitted = false;
      this.registerForm.patchValue({
        companyName:"",
        firstName:"",
        lastName:"",
        email:"",
        phone:""
      });
      this.showLoading = false;
      this.isMobileVerified=false;
      this.OTPvalue="";
      this.isOTPexpired = false;
      this.isOTPerror = false;
      this.mobileOTP = "";
      this.dailingCode = "";
      this.isphoneValid = false;
  }

  async loadTimeZone() {
    this.lstTimeZone = await this.getTimeZone();
  }

  async getTimeZone() {
    let data = await this.http.awaitGetservice(this.subscriptionAPIURL + '/Subscription/GetTimeZone');
    return data;
  }

  getSignupMaster() {

    return new Promise(resolve => {
      this.http.awaitGetservice(this.subscriptionAPIURL + '/Subscription/GetSignUpMaster')
        .then((dt: any) => {
          resolve(dt);
        });
    });
  }

  createEnquiry(_subscriberID:any) {
    return new Promise(resolve => {
      this.http.awaitGetservice(environment.subscriptionApiUrl + '/Subscription/createEnquiry?subscriperId=' + _subscriberID.toString())
        .then((dt: any) => {
          console.log('CreateEnquiry API Completed');
          resolve(dt);
        });
    });
  }

  GenerateOTP(isNonProd:boolean){
    return new Promise( resolve =>{      
      if(isNonProd){        
        // dummy response for mobile OTP verification
        var dt:any= { isSuccess : true, StatusMessage :"OTP generation success", ResponseData :{"SmsID":"1","smsOTP":"123456","StatusCode":"Success"}};
        resolve(dt);
      }
      else{
        var mobileNo:string = this.dailingCode + this.f.phone.value;      
        this.http.awaitGetservice(this.subscriptionAPIURL + '/Subscription/GetMobileOTP?Email='+this.f.email.value+'&Phone='+mobileNo)
        .then((dt:any) => {
          resolve(dt);
        });
      }
    });
  }
  mobileOTP:string="";
  isOTPerror:boolean = false;
  isOTPexpired:boolean = false;
  errmsgOTP:string="";
  async onValidateOTP(){
    if (this.mobileOTP != null && this.mobileOTP != undefined && this.mobileOTP != "") {

      let SmsID = this.localCache.getlocalStoragewithExpiry("SmsID");
      var ValidateMobileOTP = await this.http.awaitGetservice(this.subscriptionAPIURL + '/Subscription/ValidateMobileOTP?SmsID=' + SmsID + '&OTP=' + this.mobileOTP);
      if (SmsID == null) {
        this.isOTPerror = true;
        this.isOTPexpired = true;
        this.mobileOTP = "";
        this.errmsgOTP = "OTP expired";
      }
      else {
        if (ValidateMobileOTP["isSuccess"]) {
          this.isOTPerror = false;
          this.isOTPexpired = false;
          this.errmsgOTP = "";
          this.showMobileOTP.hide();
          this.isMobileVerified = true
          this.mobileOTP = "";
          // Save subscriber information, if OTP has been validated successfully.
          this.SaveSubscriber();
        }
        else if(this.OTPvalue != this.mobileOTP){
          this.isOTPerror = true;
          this.isOTPexpired = false;
          this.mobileOTP = "";
          this.errmsgOTP = "Invalid OTP";
        }
      }
    }
    else{
      this.isOTPerror = true;
      this.isOTPexpired = false;
      this.mobileOTP = "";
      this.errmsgOTP = "Please enter OTP";
    }
  }

  phonevalue = '';
  isphoneValid:boolean = false;
  onKey(event: any) { // without type info
    this.phonevalue = event.target.value;
    if(this.phoneCountry == "HK" && this.phonevalue.length == 8){
      this.isphoneValid = true;
    }
    else if(this.phoneCountry == "SG" && (this.phonevalue.length == 8)){
      this.isphoneValid = true;
    }
    else if(this.phoneCountry == "IN" && (this.phonevalue.length == 10)){
      this.isphoneValid = true;
    }
    else{
      this.isphoneValid = false;
    }
  }

  onCancelOTPmodal(){
    this.showMobileOTP.hide();
    this.isOTPerror = false;
    this.errmsgOTP = "";
    this.mobileOTP = "";
    this.isOTPexpired = false;
  }
  onResendOTP(){
    this.onCancelOTPmodal();
    this.showMobileOTP.show();
  }

  EmitCountDown(event){
    this.isOTPexpired = event;
  }
}
