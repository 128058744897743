import { Injectable } from '@angular/core';
import { HttpServices } from './http.service'
import { environment } from '../../../environments/environment';
import { AlertMessage } from '../_helpers/alert.message';
import { LocalCacheServices } from './acclocalcache.service';
import { EncryptDecryptService } from './encryptdecrypt.service';
import { promise } from 'protractor';
import { resolve } from 'path';
import { rejects } from 'assert';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EinvoiceServices {
  supplierdetails: any = [];
  orgSettings: any;
  public lhdn: Subject<any> = new Subject();
  constructor(private http: HttpServices, private alertMessage: AlertMessage, public local: LocalCacheServices, private encdec: EncryptDecryptService,
  ) { 
  }
  async SubmitEinvoice(TransID: any, TransTypeID: any,PageView:any,ContactID:any=0){
    return new Promise((resolve, reject) => {
      let payload = {  TransID: TransID,TransTypeId:TransTypeID}   
      this.http.postservice(environment.administrationApiUrl + '/Admin/SubmitDocuments', payload).subscribe({
        next: async (data: any) => {
          let res: any = data;
          if(data['status'] == "Validation"){
            let result={
               data:JSON.parse(data['message']),
               }

            this.lhdn.next(result);
          }
          else if (data['status'] == "Error") {
            if(PageView=='list'){
              this.alertMessage.errorNotifier(data['data'],0);
            }
            else{
              this.alertMessage.errorNotifier(data['message'],0);
            }
          }
          else if (data['status'] == "Partially") {
              this.alertMessage.warningNotifier(data['data'],0);
           }
          else {
            if(PageView=='list'){
              this.alertMessage.successNotifier(data['data'],0);
            }
            else{
              this.alertMessage.successNotifier(data['message'],0);                  
            }
          }
          resolve(res);
        }, error: (e: any) => {
          reject();
        }})
    })
  }
  async CancelEinvoice(TransID :any,TransTypeID :any,cancelReason :any){
    return new Promise((resolve, reject) => {
      let params = new HttpParams();
      params = params.set('TransID', TransID);
      params = params.set('TransTypeID', TransTypeID);
      params = params.set('Status',"cancelled");
      params = params.set('Reason', cancelReason);
      this.http.postservice(environment.administrationApiUrl + '/Admin/CancelEinvoice', params).subscribe({
        next: async (data: any) => {
          
          resolve(data);
        }, error: (e: any) => {
          reject();
        }})
    })
  }
}
