import { DatePipe } from '@angular/common';
import { Component, OnInit, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from '../../../../environments/environment';
import { CustomValidator, NumbersRegEx, ValidAccountCode } from '../../../utilities/_directives/customvalidator.directive';
import { AlertMessage } from '../../../utilities/_helpers/alert.message';
import { AccsessioncacheService } from '../../../utilities/_services/accsessioncache.service';
import { AutomatebankfeedsService } from '../../../utilities/_services/automatebankfeeds.service';
import { BankaccountService } from '../../../utilities/_services/bankaccount.service';
import { HttpServices } from '../../../utilities/_services/http.service';
import { MasterServices } from '../../../utilities/_services/master.service';

@Component({
  selector: 'app-editaccount',
  templateUrl: './editaccount.component.html',
  styleUrls: ['./editaccount.component.scss']
})
export class EditaccountComponent implements OnInit {
  accedit = false;
  loading = false;
  accountTypes: any;
  masterAccountTypes: any;
  submitted = false;
  editedAccount: any;
  isEditForm = false;
  allrowData: any;
  accCodeExists: any = {};
  editAccountForm: FormGroup | any;
  accountId: number;
  bankId: number = 0;
  CAAccountID: number = 0;
  isProduction: boolean = false;
  @ViewChild('showEditAccount') public showEditAccount: ModalDirective;
  @ViewChild('DelinkPopup') public DelinkPopup: ModalDirective;

  @Output() sentToParent = new EventEmitter<String>();

  constructor(private bankService: BankaccountService, public datepipe: DatePipe, private master: MasterServices,
    private formBuilder: FormBuilder, private el: ElementRef, private alertMessage: AlertMessage,
    private http: HttpServices, private _activatedRoute: ActivatedRoute, private router: Router,
    private automatebankfeedsService: AutomatebankfeedsService,
    private session: AccsessioncacheService) {
    this.isProduction = environment.production;
  }

  ngOnInit(): void {
    setTimeout(async () => {
      this.accountTypes = await this.master.getBankAccountTypes();
      this.masterAccountTypes = await this.master.getBankAccountTypes();

      var parmURL = '/ChartofAccounts/GetChartOfAccount?accountGroupID=0';
      this.http.getservice(environment.accountsApiUrl + parmURL).subscribe({
        next: (data) => {
          this.allrowData = data;
        }
      });
    });
  }

  get f() { return this.editAccountForm.controls; }

  
  editAccount(obj) {
    this.accedit = false;
    this.editedAccount = obj;
    // let disabled = (obj.IsAuthennticatedtoGetAutoFeed && obj.providerAccountId != undefined && obj.providerAccountId != null);
    let disabled = false;
    this.editAccountForm = this.formBuilder.group({
      AccountName: new FormControl({ value: '', disabled: disabled }, [Validators.required]),
      BankAccountNo: new FormControl({ value: '', disabled: '' }, [Validators.required]),
      AccountCode: ['', [Validators.pattern(ValidAccountCode.pattern)]],
      BankAccTypeID: new FormControl({ value: 0, disabled: disabled }, [CustomValidator.required]),
    });
    this.accCodeExists = { code: '', name: '', isExists: false, isShow: false };
    this.editAccountForm.patchValue(obj);
    this.accountTypes = this.masterAccountTypes;
    if (obj.BankAccTypeName === "Cash in hand") {
      this.editAccountForm.controls['BankAccountNo'].clearValidators();
      this.editAccountForm.controls['BankAccountNo'].updateValueAndValidity();
    } else {
      this.editAccountForm.controls['BankAccountNo'].setValidators([Validators.required, 
        RxwebValidators.pattern({ expression: { 'numberPattern': /^[a-zA-Z0-9]+$/ } })])
      this.editAccountForm.controls['BankAccountNo'].updateValueAndValidity();
    }

    if (obj.BankAccTypeID == 4) {
      this.editAccountForm.get('BankAccTypeID').disable();
    } else {
      this.accountTypes = this.accountTypes.filter((o: any) => o.BankAccTypeID != 4);
    }

    this.isEditForm = true;
    this.showEditAccount.show();

    this.accountId = obj.accountId;
    this.CAAccountID = obj.CAAccountID;

    // if (obj.IsAuthennticatedtoGetAutoFeed && obj.providerAccountId != undefined && obj.providerAccountId != null) {
    //   let routerurl = this.router.url;
    //   let spliturl = routerurl.split("?");
    //   let url = spliturl[0];
    //   this.router.navigate(['/bankaccounts/yodleefeedaccountedit'], { queryParams: { editedAccount: btoa(JSON.stringify(obj)), currentUrl: btoa(encodeURIComponent(url)) } });
    // }
    // else {
    //   this.editAccountForm.patchValue(obj);
    //   this.isEditForm = true;
    //   this.showEditAccount.show();
    // }
  }

  statusMsgID: any = 0;

  async submitAccountForm() {
    this.submitted = true;
    this.loading = true;

    if (this.editAccountForm.invalid) {
      this.loading = false;
      const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector("form .ng-invalid");
      firstInvalidControl.focus();
      return;
    }

    if (this.accCodeExists.isExists == true) {
      this.loading = false;
      return;
    }

    const myData = {
      accountCode: this.f.AccountCode.value.toString(),
      accountName: this.f.AccountName.value.toString(),
      accountNumber: this.f.BankAccountNo.value.toString(),
      accountTypeID: this.f.BankAccTypeID.value.toString(),
      currencyCode: this.editedAccount.CurrencyCode.toString(),
      IsAuthennticatedtoGetAutoFeed: this.editedAccount.IsAuthennticatedtoGetAutoFeed,
      CAAccountID: this.editedAccount.CAAccountID,
      oldAccountNumber: this.editedAccount.BankAccountNo
    };

    (await this.bankService.SaveBankAccount(myData, this.editedAccount.BankCode)).subscribe({
      next: (data: any) => {
        let retundata: any = data;
        if (retundata.responseData == "1") {
          this.loading = false;
          this.sentToParent.emit('save');
          this.cancelAccountForm();
          this.ngOnInit();
        }
        else {
          this.loading = false;
          this.accCodeExists = { code: myData.accountCode, name: myData.accountName, isExists: true, isShow: true };
          this.statusMsgID = this.alertMessage.errorNotifier(retundata.responseData, this.statusMsgID);
        }
      }
    });
  }

  cancelAccountForm() {
    this.submitted = false;
    this.editAccountForm.reset();
    this.editedAccount = null;
    this.isEditForm = false;
    this.showEditAccount.hide();

  }

  yodleeUpdate() {
    if (this.editedAccount.IsAuthennticatedtoGetAutoFeed && this.editedAccount.providerAccountId != undefined && this.editedAccount.providerAccountId != null) {
      let routerurl = this.router.url;
      let spliturl = routerurl.split("?");
      let url = spliturl[0];
      this.router.navigate(['/bankaccounts/yodleefeedaccountedit'], { queryParams: { editedAccount: btoa(JSON.stringify(this.editedAccount)), currentUrl: btoa(encodeURIComponent(url)) } });
    }
  }

  accountCodeExists() {
    if (!this.editAccountForm.value.AccountCode.toString().match(/^[ ^!@#$%^&*()`~+=\{\}\[\]\\:;\"<>?,|]*$/g)) {
      return false;
    }
    if (this.editAccountForm.value.AccountCode != '') {
      let accCode = this.allrowData.filter(item => item.accountCode == this.editAccountForm.value.AccountCode);

      if (accCode.length > 0) {
        if (this.editedAccount.CAAccountID != accCode[0].caAccountID) {
          this.accCodeExists = { code: accCode[0].accountCode, name: accCode[0].accountName, isExists: true, isShow: true };
        }
        else {
          this.accCodeExists = { code: '', name: '', isExists: false, isShow: false };
        }
      }
      else {
        this.accCodeExists = { code: this.editAccountForm.value.AccountCode, name: '', isExists: false, isShow: true };
      }
    }
    else {
      this.accCodeExists = { code: '', name: '', isExists: false, isShow: false };
    }
  }

  RemoveAutoFeedAccount() {
    let requestParam = {
      accountId: this.accountId,
      environment: environment.yodleeEnvironment
    };
    this.automatebankfeedsService.RemoveAutoFeedAccount(requestParam).then((data) => {
      let response = data["responseData"];
      if (response['isSuccess']) {
        this.alertMessage.successNotifier('Auto Feed De-Linked successfully', 0);
      } else {
        this.alertMessage.errorNotifier('Failed to update, Please check with administrator', 0);
      }
    });
  }

  DeactivateAutoFeedAccount() {
    let requestParam = {
      accountId: this.accountId
    };
    this.automatebankfeedsService.DeactivateAutoFeedAccount(requestParam).then((data) => {
      let response = data;
      if (response['isSuccess']) {
        this.alertMessage.successNotifier('Auto Feed Deactivated successfully', 0);
        this.showEditAccount.hide();

        setTimeout(() => {
          window.location.href = '/bankaccounts/bankaccounts'
        }, 1000);

      } else {
        this.alertMessage.errorNotifier('Failed to update, Please check with administrator', 0);
      }
    });
  }

  // async ReactivateAutoFeedAccount() {
  //   let requestParam = {
  //     accountId: this.accountId
  //   };
  //   await this.automatebankfeedsService.GetBankIdByAccountId(requestParam).then(async (data) => {
  //     let response = data;
  //     if (response['isSuccess']) {
  //       var bankObjects = response["responseData"];
  //       this.bankId = bankObjects[0]["bankId"];
  //       if (this.bankId > 0) {
  //         await this.automatebankfeedsService.GetAutoFeedLoginUrlNew(this.bankId).then((data) => {
  //           if (data["url"] != "") {
  //             var url = data["url"];
  //             window.location.href = url;
  //           } else {
  //             this.alertMessage.errorNotifier('Failed to Connect OCBC server', 0);
  //             this.showEditAccount.hide();
  //           }
  //         });
  //       }
  //     } else {
  //       this.alertMessage.errorNotifier('Failed to Connect OCBC server, Please check with administrator', 0);
  //       console.log(response['statusMessage']);
  //     }
  //   });
  // }

  async ReactivateAutoFeedAccount() {
    let requestParam = {
      accountId: this.accountId
    };
    await this.automatebankfeedsService.ReactivateAutoFeedAccount(requestParam).then(async (data) => {
      let response = data;
      if (response['isSuccess']) {
        this.alertMessage.successNotifier('Auto Feed Reactivated successfully', 0);
        this.showEditAccount.hide();
        // this.alertMessage.successRoutingNotifier('Auto Feed Reactivated successfully', '/bankaccounts/bankaccounts','');
        setTimeout(() => {
          window.location.href = '/bankaccounts/bankaccounts'
        }, 1000);
      } else {
        this.alertMessage.errorNotifier('Failed to update, Please check with administrator', 0);
      }
    });
  }

  delinkAccount() {
    this.DelinkPopup.show();
  }

  cancelDelinkPopup() {
    this.DelinkPopup.hide();
  }
}
