import { Component, Input, OnInit, Output, ViewChild, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AlertMessage } from '../../../../utilities/_helpers/alert.message';
import { SavePublishComponent } from '../../../reports/save-publish/save-publish.component';
import { reporttype } from '../../../reports/Service/pdfmake.model';
import { ReportDataPassingService } from '../../../reports/Service/report-data-passing.service';
import { saveAsType } from '../../../reports/Service/report.model';
import { Group } from '../../../reports/Service/reportgrouping.service';
import { floatingScroll } from './floating-scroll-reports';
import { PublishGroupingService } from '../../../reports/Service/publish-report/publish-grouping.service';
import { LocalCacheServices } from '../../../../utilities/_services/acclocalcache.service';
import { TranslateService } from '@ngx-translate/core';
import { TreeviewService } from '../../../reports/Service/treeview.service';

@Component({
  selector: 'report-grouping-mat-table',
  templateUrl: './report-mat-table.component.html',
  styleUrls: ['./report-mat-table.component.scss']
})
export class ReportMatTableComponent implements OnInit, OnChanges {

  @Input() columns = [];
  @Input() alldata = [];
  @Input() dataSource = new MatTableDataSource<any | Group>([]);
  @Input() displayedColumns = [];
  @Input() groupByColumns = [];
  @Input() totalCount: number = 0;
  @Input() loading: boolean;
  @Input() pageSize: number = 100;
  @Input() pageNumber: number = 0;
  @Input() sortorder: boolean = false;
  @Input() title: string;
  @Input() isFooterRequired: any = { IsExportRequired: false, IsSaveAsDraftRequired: false, IsPaginationRequired: false, IsEditLayoutRequired: false };
  @Input() reportTitle = '';
  @Input() dateSelected = '';
  @Input() OrgName = '';
  @Input() orgSelected = [];
  @Input() fieldData: any;
  @Input() ReportID: any;
  @Input() fromDate: any;
  @Input() toDate: any;
  @Input() treeControl:any;
  @Output() MatSort = new EventEmitter<MatSort>();
  @Output() ChangePagination = new EventEmitter<any>();
  @Output() exportFile = new EventEmitter<any>();
  @Output() saveAs = new EventEmitter<any>();
  @Output() viewJournal = new EventEmitter<any>();
  @Output() editLayout = new EventEmitter<any>();
  @Output() expandCollapse = new EventEmitter<any>();
  @Input() CFColumnsSelected=[];
  innerColumns: any[] = [{field:'TransNo',title:'Trans No', align:'tr-left'},{field:'Date',title:'Trans Date', align:'tr-left'} ,
  {field:'DueDate',title:'Due Date', align:'tr-left'},{field:'UnitTypeName',title:'UOM', align:'tr-left'},{field:'Qty',title:'Qty', align:'tr-left'}];
  displayedinnercolumns:any[]=['TransNo','Date','DueDate','UnitTypeName','Qty'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  saveAsTypeDetail = saveAsType;
  rowTypeList = ['total', 'subtotal', 'closing balance', 'opening balance', 'operating expenses', 'header'];
  hyperLinkColumnName = ['linkDetail', 'linkDetailReport', 'LinkDetail', 'LinkDetailReport'];
  curentUser: any;
  orgId: any;
  orgName: any;
  localCurrentUser: any;
  @Input() isDraft:boolean;
  @Input() draftReportTitle:any;
  constructor(public matDialog: MatDialog, private alertMessage: AlertMessage,
    private reportDataPassingService: ReportDataPassingService,private ReportService: PublishGroupingService,
    private local: LocalCacheServices, private translate: TranslateService, private treeViewService: TreeviewService
  ) {
    this.loading = true;
    this.dataSource = new MatTableDataSource<any | Group>([]);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.curentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentOrganization));
    this.localCurrentUser = JSON.parse(
      this.local.getlocalStorage(this.local.localCurrentUserKey)
    );
    this.orgId = this.localCurrentUser["orgID"];
    this.orgName = this.localCurrentUser["organizationName"];
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pageNumber) {
      if (this.paginator) {
        this.paginator.pageIndex = this.pageNumber;
      }
    }

    if(changes.columns){
      //this.loading = true;
      this.columns = JSON.parse(JSON.stringify(this.columns))    
     
        setTimeout(() => {
          this.loading = false;
        }, 10);
    }

    setTimeout(() => {
      floatingScroll.scroll(document.getElementById("report-container"));
    }, 100);

  }

  ngOnInit(): void {

  }

  onsalesorderSortData(sort: MatSort) {
     this.loading = true;
     let data = this.alldata;
     let val=[];
     if(this.groupByColumns.toString()=="None"){
      val=data[data.length-1];
      data.splice(data.length-1,1);
     }
    if (sort.active && sort.direction !== '') {
       this.dataSource.data = [];
       let type = this.columns.filter(res => res.field == sort.active)[0].type;
       data = data.sort((a: any, b: any) => {
        const isAsc = sort.direction === 'asc';
        return this.Datacompare(a[sort.active], b[sort.active], isAsc, type);
      });
    }
    let newdata=[];
    if(this.groupByColumns.toString()!="None"){
       newdata = this.ReportService.addGroups(data, this.groupByColumns);
    }
    else{
        data.push(val);
        newdata=data;
    }
    let reportData = [];
    newdata.forEach(r=>{
        reportData.push(r);
        if(r.InvDoDetails!='' && r.InvDoDetails!=undefined){
          reportData.push({json: JSON.parse(r.InvDoDetails) , isChild:true});
        }
       })
       this.dataSource.data=reportData
    this.dataSource.paginator = this.paginator;
    this.loading = false;
  }
  private Datacompare(a, b, isAsc, type) {
     return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  trackByIndex(i) { return i; }

  public getInnerHtml(element) {
    return `<span>${element}</span>`;
  }

  isGroup(index, item): boolean {
    return item.level;
  }

  onSortData(sort: MatSort) {
    this.MatSort.emit(sort);
  }
  changePagination($event) {
    this.ChangePagination.emit($event)
  }
  ischild(index:any,item){
    return item.isChild;
  }
  exportExcelFile() {
    this.exportFile.emit(reporttype.ExcelDownload)
  }
  exportPDF() {
    this.exportFile.emit(reporttype.PDFDownload)
  }

  saveAsPublish(event) {
    if (this.totalCount == 0) {
      this.alertMessage.errorTitleNotifier(this.translate.instant("ReportsModule.No record found"), "Information", 0);
      return;
    }
    if (event == saveAsType.Published) {
      this.matDialog.open(SavePublishComponent, {
        width: '800px',
        disableClose: true,
        position: { top: '75px' },
        panelClass: "dialog-p-0",
        data: { reportTitle: this.draftReportTitle==undefined ? this.reportTitle:this.draftReportTitle, date: this.dateSelected, orgSelected: this.orgSelected, saveAsTypeDetail: saveAsType.Published,ReportID:this.ReportID }
      }).afterClosed().subscribe(result => {
        if (result) {
          result.event = event;
          this.saveAs.emit(result);
        }
      });
      return;
    }
    if (event == saveAsType.Draft) {
      this.matDialog.open(SavePublishComponent, {
        width: '800px',
        disableClose: true,
        position: { top: '75px' },
        panelClass: "dialog-p-0",
        data: { reportTitle: this.draftReportTitle==undefined ? this.reportTitle:this.draftReportTitle, date: this.dateSelected, orgSelected: this.orgSelected, saveAsTypeDetail: saveAsType.Draft,ReportID:this.ReportID }
      }).afterClosed().subscribe(result => {
        if (result) {
          result.event = event;
          this.saveAs.emit(result);
        }
      });
      return;
    }
  }

  navigaterouterLink(data, column?) {
    return this.reportDataPassingService.navigaterouterLink(data, column, this.fieldData);
  }
  navigatequeryParams(data, column?) {
    return this.reportDataPassingService.navigatequeryParams(data, column, this.fieldData, this.fromDate,this.toDate);
  }

  navigate(data, column?) {
    var jsonData = null;
    if (data?.linkDetail) {
      jsonData = JSON.parse(data?.linkDetail);
    }
    else if (data?.LinkDetail) {
      jsonData = JSON.parse(data?.LinkDetail);
    }

    if (jsonData?.PageID && jsonData.PageID === 259) {
      this.reportDataPassingService.navigateToReports(data, column);
    }
    else if (jsonData?.TransTypeId && ((jsonData.TransTypeId === 13)||(jsonData.TransTypeId === 26))) {
      const fixedAsset = {
        transactionTypeid: jsonData.TransTypeId,
        transactionId: jsonData.TransactionId,
        transactionDetailId: jsonData.TransDetailId,
        isReverseEntry: jsonData.IsReversed
      }
      this.viewJournal.emit(fixedAsset);
    } else if (column?.field == 'linkDetail' || column?.field == 'LinkDetail') {
      if (data) {
        let linkDetails = data.linkDetail ? data.linkDetail : data.LinkDetail;
        if (linkDetails)
          this.reportDataPassingService.navigate(linkDetails);
      }
    } else {
      if (this.reportTitle == "Balance Sheet" || this.reportTitle == "Trial Balance") {
        let orgDetails = this.curentUser;
        let orgFinanceStart = new Date(orgDetails.financeStart);
        let orgFinanceEnd = new Date(orgDetails.financeEnd);
        let toDate = new Date(this.toDate); // Date Format like (Day DD MMM YYYY)
        if(orgFinanceEnd.getMonth() == 11){ // Index of the Month[0-11] For Dec Month start date is Jan
          this.fromDate = new Date(toDate.getFullYear(), 0, orgFinanceStart.getDate());
        }
        else if(orgFinanceEnd.getMonth() == 1){ // Feb Month calculate for Leap year also
          let selectedDate=new Date(column.field);
          /* if selected date is less the org start date & month and same year */
          if(selectedDate.getDate() < orgFinanceStart.getDate() && selectedDate.getMonth() < orgFinanceStart.getMonth() && selectedDate.getFullYear() == orgFinanceStart.getFullYear()){
            this.fromDate = new Date(toDate.getFullYear() - 1, orgFinanceStart.getMonth(), orgFinanceStart.getDate());
          }
          else{
            /* if selected date & month is same with org end date & month */
            if(selectedDate.getDate()==orgFinanceEnd.getDate() && selectedDate.getMonth()==orgFinanceEnd.getMonth()){
              this.fromDate = new Date(selectedDate.getFullYear() -1, orgFinanceStart.getMonth(), orgFinanceStart.getDate());
            }
            else{
              this.fromDate = new Date(toDate.getFullYear(), orgFinanceStart.getMonth(), orgFinanceStart.getDate()); // current month/year/today
            }

          }
        }
        else if (orgFinanceEnd.getMonth() < 11 &&  toDate.getMonth() < orgFinanceStart.getMonth()) { // Below Dec we calculate the from date with previous year except Feb
            this.fromDate = new Date(toDate.getFullYear()-1, orgFinanceStart.getMonth(), orgFinanceStart.getDate());
        }
        else{
          this.fromDate = new Date(toDate.getFullYear(), orgFinanceStart.getMonth(), orgFinanceStart.getDate());
        }
      }
      if (this.reportTitle == "Trial Balance" || this.reportTitle == "Profit and Loss") {
        if(this.CFColumnsSelected.length > 0 && (column.field != "Debit" && column.field != "Credit" )){
            this.fieldData.filterlist=JSON.stringify(this.CFColumnsSelected);
            this.fieldData.customfields=JSON.stringify(column);
        }
      }
      this.reportDataPassingService.navigateToReports(data, column, this.fieldData, this.fromDate,this.toDate);
    }
  }

  openEditLayout() {
    this.editLayout.emit(true);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      floatingScroll.scroll(document.getElementById("report-container"));
      this.expandAll();
    }, 1000);
    this.expandAll();
  }
  
  expandAll() {
    this.treeViewService.expandAll(this.alldata, this.treeControl);
  }

  getExtralength() {
    const columnsWithExtraLength = [
      { ColumnsName: 'AccountName', Width: 380 },
    ];
    return this.columns?.reduce((acc, column) => {
      const extraColumn = columnsWithExtraLength.find(extra => extra.ColumnsName.toLowerCase() === ((column.field?? '') as string).toLowerCase());
      return acc + (extraColumn ? extraColumn.Width - 115 : 0);
    }, 0);
  }
  showEyeIcon(orgName){
    if(orgName == undefined || orgName == this.orgName){
      return true
    }
    return false
  }

  expandCollapseAll(e){
    this.expandCollapse.emit(e);
  }

}
