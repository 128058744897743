<div bsModal #showaddItemmodal="bs-modal" class="modal fade modal-fullscreen" tabindex="-1" role="dialog"
    aria-labelledby="showaddItemmodalLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header pb-0">
                <div class="col-md-2">
                    <h4 class="modal-title" *ngIf="itemID==0">{{'InventoryModule.Item.Add Item' | translate}}</h4>
                    <h4 class="modal-title" *ngIf="itemID >0">{{'InventoryModule.Item.Edit Item' | translate}}</h4>
                </div>
                <div class="col-md-4">
                    <div class="c-nav-tabs">
                        <ul class="nav custom-nav-5" role="tablist">
                            <ng-container *ngIf="itemID==0">
                                <li class="nav-item text-center" *ngFor="let item of lstItemType"
                                    (click)="onChangeItemType(item.itemTypeID)">
                                    <a [ngClass]="selecteditemTypeID==item.itemTypeID ?'nav-link active':'nav-link'"
                                        data-bs-toggle="tab" href="#tab_all" role="tab">

                                        <i *ngIf="item.itemTypeID ==1" class="fa fa-cube" aria-hidden="true"></i>
                                        <i *ngIf="item.itemTypeID ==2" class="fa fa-wrench" aria-hidden="true"></i>
                                        <i *ngIf="item.itemTypeID ==3" class="fa fa-cubes" aria-hidden="true"></i>
                                        <br>{{item.typeName | translate}}</a>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="itemID>0">
                                <li class="nav-item  text-center" *ngFor="let item of lstItemType">
                                    <a [ngClass]="selecteditemTypeID==item.itemTypeID ?'nav-link active':'nav-link disabled'"
                                        data-bs-toggle="tab" href="#tab_all" role="tab">
                                        <i *ngIf="item.itemTypeID ==1" class="fa fa-cube" aria-hidden="true"></i>
                                        <i *ngIf="item.itemTypeID ==2" class="fa fa-wrench" aria-hidden="true"></i>
                                        <i *ngIf="item.itemTypeID ==3" class="fa fa-cubes" aria-hidden="true"></i>
                                        <br>{{item.typeName }}</a>
                                </li>
                            </ng-container>

                        </ul>
                    </div>
                </div>
                <div class="col-md-6"> <button type="button" class="close" (click)="hideaddItemmodal()">
                        <span aria-hidden="true">×</span>
                    </button></div>
            </div>
            <div class="modal-body">                
                <div class="tab-content border-0">
                    <div class="tab-pane active" id="tab_all" role="tabpanel">
                        <form class="form-horizontal needs-validation" novalidate="novalidate"
                            [formGroup]="inventoryForm">
                            <div class="row">
                                <div [class]="f.itemTypeID.value==3?'col-md-12':'col-md-9'">
                                    <div class="row">
                                        <div [class]="f.itemTypeID.value==3 && currentUser.orgCountryID != 129?'col-md-3':'col-md-4'">
                                            <label class="col-form-label" for="itemCode">{{'InventoryModule.Item.Item Code/SKU' | translate}}
                                                <span class="text-danger">*</span>
                                            </label>
                                            <input type="text" class="form-control" maxlength="100" formControlName="itemCode"
                                                [ngClass]="{ 'is-invalid': submitted && f.itemCode.errors }" xssDirective />
                                            <div *ngIf="submitted && f.itemCode.errors" class="invalid-feedback">
                                                <div *ngIf="f.itemCode.errors.required">{{'Common.Is required' | translate}}</div>
                                            </div>
                                        </div>
                                        <div [class]="f.itemTypeID.value==3 && currentUser.orgCountryID != 129?'col-md-3':'col-md-4'">
                                            <label class="col-form-label" for="itemName">{{'InventoryModule.Item.Item Name' | translate}}
                                                <span class="text-danger">*</span>
                                            </label>
                                            <input type="text" class="form-control" maxlength="100" formControlName="itemName"
                                                (blur)="onthisItem('')" xssDirective
                                                [ngClass]="{ 'is-invalid': submitted && f.itemName.errors }" />
                                            <div *ngIf="submitted && f.itemName.errors" class="invalid-feedback">
                                                <div *ngIf="f.itemName.errors.required">{{'Common.Is required' | translate}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-4" *ngIf="currentUser.orgCountryID == 129">
                                            <label class="col-form-label" for="itemClassificationCode">Item Classification Code
                                                <span class="text-danger">*</span>
                                            </label>
                                            <div class="input-group">
                                                <ng-select style="width: 84%;" formControlName="itemClassificationCode"
                                                    [(ngModel)]="clCode"
                                                    [ngClass]="{ 'is-invalid': submitted && f.itemClassificationCode.errors }"
                                                    [items]="ClassificationCode" bindLabel="description"
                                                    bindValue="code" xssDirective>
                                                </ng-select>
                                                <div class="input-group-append">
                                                    <button type="button" class="btn btn-sm" style="border: 1px solid #ababab" (click)="ClassificationCodeMatch()" matTooltip="Auto Detect from Item Name"
                                                        [disabled]="isClCodeDisable"><i class="fa fa-bolt" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                            <div *ngIf="submitted && f.itemClassificationCode.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="f.itemClassificationCode.errors.required">{{'Common.Is
                                                    required' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                        <div [class]="currentUser.orgCountryID == 129?'col-md-4':'col-md-4'" *ngIf="f.itemTypeID.value!=3">
                                            <label class="col-form-label" for="itemCode">{{'InventoryModule.Item.Product Category' | translate}}
                                            </label>
                                            <ng-select formControlName="productCategoryID" style="display: none;"
                                                [items]="lstProductCategory" bindLabel="productCategoryName"
                                                bindValue="productCategoryID" placeholder="Select Product" addTagText="Add New"
                                                [addTag]="addTagPromise" xssDirective></ng-select>

                                            <ng-select formControlName="productCategoryID" [items]="lstProductCategory"
                                                bindValue="productCategoryID" bindLabel="productCategoryName"
                                                [searchFn]="customSearchFn" typeToSearchText="Please enter 2 or more characters"
                                                (search)="onSearchForApi($event)" xssDirective>
                                                <ng-template ng-header-tmp *ngIf="showNewProductBtn">
                                                    <a class="t-link" (click)="addNewProduct($event)"><b>+</b>{{'InventoryModule.Item.Add Category' | translate}}</a>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                        <div [class]="currentUser.orgCountryID == 129?'col-md-4':'col-md-4'" *ngIf="f.itemTypeID.value!=3">
                                            <label class=" col-form-label" for="itemName">{{'InventoryModule.Item.Unit of Measurement' | translate}}
                                            </label>
                                            <ng-select formControlName="itemUnitTypeID" [items]="lstItemUnitType"
                                                bindValue="itemUnitTypeID" bindLabel="itemUnitTypeName">
                                            </ng-select>
                                        </div>
                                        <div class="col-md-6" *ngIf="f.itemTypeID.value==3">
                                            <label class="col-form-label" for="productGroupDescription">{{'InventoryModule.Item.Description' | translate}}<br>
                                            </label>
                                            <textarea class="form-control" rows="3" maxlength="250" xssDirective
                                                formControlName="groupDescription"></textarea>
                                        </div>
                                        <div class="col-md-6" *ngIf="f.itemTypeID.value==3">
                                            <div class="custom-control-mid custom-checkbox">
                                                <input type="checkbox" class="custom-control-input"
                                                    formControlName="isHideUnitPrice" id="isHideUnitPrice">
                                                <label class="custom-control-label" for="isHideUnitPrice">{{'InventoryModule.Item.Hide UnitPrice From Print' | translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 d-flex justify-content-center align-items-center" *ngIf="f.itemTypeID.value!=3">
                                    <div class="file-upload-area" fileDragDrop (fileDropped)="onFileChange($event)">
                                        <span class="img-close" *ngIf="imageSrc" aria-hidden="true" (click)="onShowHide();">×</span>
                                        <input id="fileUpload" type="file" #fileUploader accept="image/png,image/jpeg,image/jpg"
                                               (change)="onFileChange($event)" class="d-none">
                                        <label class="file-upload-label" (click)="!imageSrc && fileUploader.click()">
                                            <div class="file-preview" *ngIf="imageSrc">
                                                <div class=" img-zoom-container" (mouseenter)="imageZoom('myimage1', 'myresult1')" 
                                                (mouseleave)="resetZoom('myresult1')">
                                                <img id="myimage1" [src]="imageSrc" class="small-image" (click)="openImageViewer()">
                                            </div>
                                            <div id="myresult1" class="img-zoom-result" style="display: none;"></div>
                                            </div>
                                           
                                            <div class="upload-placeholder" triggers="mouseenter:mouseleave"
                                            [popover]="popTemplateHtml" popoverTitle="{{'Note' | translate}}" *ngIf="!imageSrc">
                                                <span  >{{ 'Upload Image' | translate }}</span>
                                                <i class="fa fa-info-circle pl-1 popover-override"  ></i>
                                                <ng-template #popTemplateHtml>
                                                    <div class="help-block text-warning"> 
                                                        {{'The maximum file size is 1MB. Image formats should be jpeg/jpg/png. Please upload images with a high resolution to ensure better quality during zooming.' | translate}}
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </label>
                                    </div>

                                    
                                </div>
                            </div>
                            <app-dynamicitemfield></app-dynamicitemfield>
                            <div class="row" *ngIf="f.itemTypeID.value!=3">
                                <div class="col-md-6">
                                    <div class="border-addon">
                                    <div class="form-title h2">
                                        <div class="custom-control-mid custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="chkPurchaseItem"
                                                [attr.disabled]="f.isTrackedItem.value==true ? true : null"
                                                (change)="onthisItem('P');" formControlName="isIPurchase">
                                            <label class="custom-control-label" for="chkPurchaseItem">{{'InventoryModule.Item.I purchase this item' | translate}}</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label class="col-form-label" for="purchasePrice">{{'InventoryModule.Item.Cost Price' | translate}}</label>
                                            <input type="text" class="form-control"
                                                [readonly]="f.isIPurchase.value==true?false:true" maxlength="15"
                                                decimal4 formControlName="purchasePrice"
                                                [ngClass]="{ 'is-invalid':f.purchasePrice.errors }" />
                                            <div *ngIf="f.purchasePrice.errors" class="invalid-feedback">
                                                <div *ngIf="f.purchasePrice.errors.pattern">
                                                    {{'InventoryModule.Item.Please provide valid price.' | translate}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="col-form-label" for="purchaseAccountID">{{'InventoryModule.Item.Purchase Account' | translate}}</label>
                                            <ng-select [items]="lstPurchaseAccount"
                                                [readonly]="f.isIPurchase.value==true?false:true" class="auto-grow"
                                                bindLabel="DisplayAccountName" bindValue="CAAccountID"
                                                (change)="onChangeAccount('P')" groupBy="AccountGroupName"
                                                formControlName="purchaseAccountID"
                                                [ngClass]="{ 'is-invalid': submitted && f.purchaseAccountID.errors }">
                                                <ng-template ng-optgroup-tmp let-item="item">
                                                    {{item.AccountGroupName || 'Unnamed group'}}
                                                </ng-template>
                                            </ng-select>
                                            <div *ngIf="submitted && f.purchaseAccountID.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="f.purchaseAccountID.errors.required">{{'Common.Is required' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="col-form-label" for="purchaseTaxID">{{'OrgModlue.Tax Rates.Tax Rate' | translate}}</label>
                                            <ng-select [items]="lstPurchaseTaxRate"
                                                [readonly]="f.isIPurchase.value==true?false:true" class="auto-grow"
                                                bindLabel="taxName" bindValue="mstrTaxRateID" [clearable]="false"
                                                formControlName="purchaseTaxID">
                                            </ng-select>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="col-form-label" for="purchaseDescription">{{'InventoryModule.Item.Purchase Description' | translate}}
                                                <span class="help-block small text-muted">{{'InventoryModule.Item.(for my suppliers)' | translate}}</span>
                                                <a *ngIf="enableHTMLDesc" class="t-link" (click)="addMore('P')">
                                                    &nbsp;<i class="fa fa-file-text" aria-hidden="true"></i></a>
                                            </label>
                                            <textarea class="form-control" rows="3" maxlength="8000"
                                                [readonly]="f.isIPurchase.value==true?false:true" xssDirective
                                                formControlName="purchaseDescription"></textarea>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="border-addon">
                                    <div class="form-title h2">
                                        <div class="custom-control-mid custom-checkbox">
                                            <input type="checkbox"
                                                [attr.disabled]="f.isTrackedItem.value==true ? true : null"
                                                class="custom-control-input" id="chkISellItem" formControlName="isISell"
                                                (change)="onthisItem('S');">
                                            <label class="custom-control-label" for="chkISellItem">{{'InventoryModule.Item.I sell this item' | translate}}</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label class="col-form-label" for="salesPrice">{{'InventoryModule.Item.Selling Price' | translate}}</label>
                                            <input type="text" class="form-control" maxlength="15" decimal4
                                                [readonly]="f.isISell.value==true?false:true"
                                                formControlName="salesPrice"
                                                [ngClass]="{ 'is-invalid':f.salesPrice.errors }" />
                                            <div *ngIf="f.salesPrice.errors" class="invalid-feedback">
                                                <div *ngIf="f.salesPrice.errors.pattern">
                                                    {{'InventoryModule.Item.Please provide valid price.' | translate}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="col-form-label" for="salesAccountID">{{'InventoryModule.Item.Sales Account' | translate}}</label>
                                            <ng-select [items]="lstSalesAccount"
                                                [readonly]="f.isISell.value==true?false:true" class="auto-grow"
                                                bindLabel="DisplayAccountName" bindValue="CAAccountID"
                                                (change)="onChangeAccount('S')" groupBy="AccountGroupName"
                                                formControlName="salesAccountID"
                                                [ngClass]="{ 'is-invalid': submitted && f.salesAccountID.errors }">
                                                <ng-template ng-optgroup-tmp let-item="item">
                                                    {{item.AccountGroupName || 'Unnamed group'}}
                                                </ng-template>
                                            </ng-select>
                                            <div *ngIf="submitted && f.salesAccountID.errors" class="invalid-feedback">
                                                <div *ngIf="f.salesAccountID.errors.required">{{'Common.Is required' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="col-form-label" for="salesTaxID">{{'OrgModlue.Tax Rates.Tax Rate' | translate}}</label>
                                            <ng-select [items]="lstSalesTaxRate"
                                                [readonly]="f.isISell.value==true?false:true" class="auto-grow"
                                                bindLabel="taxName" bindValue="mstrTaxRateID" [clearable]="false"
                                                formControlName="salesTaxID">
                                            </ng-select>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="col-form-label" for="SalesDescription">{{'InventoryModule.Item.Sales Description' | translate}}
                                                <span class="help-block small text-muted">{{'InventoryModule.Item.(for my customers)' | translate}}</span>
                                                <a *ngIf="enableHTMLDesc" class="t-link" (click)="addMore('S')">
                                                    &nbsp;<i class="fa fa-file-text" aria-hidden="true"></i></a>
                                            </label>
                                            <textarea class="form-control" rows="3" maxlength="8000"
                                                [readonly]="f.isISell.value==true?false:true" xssDirective
                                                formControlName="salesDescription"></textarea>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf='this.curentUser.orgCountryID === 12999'>
                                <div class="row">
                                    <div class="col-md-3">
                                        <label class="col-form-label" for="TariffID">{{'InventoryModule.Item.Tariff Code' | translate}} </label>
                                        <ng-select [items]="lstTariffCode" class="auto-grow" bindLabel="tariffCodeName"
                                            bindValue="tariffID" [clearable]="true" formControlName="tariffID">
                                        </ng-select>
                                    </div>
                                </div><br>
                            </ng-container>

<ng-container *licenseType="'trackeditem'">
    <div class="border-addon" *ngIf="f.itemTypeID.value==1">
    <div class="row" >
        <div class="col-md-12">
            <div class="form-title h2">
                <div class="custom-control-mid custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="chkKeepTrackItem"
                           formControlName="isTrackedItem" (change)="onTrackItem();"
                           *ngIf="f.canEditTrackedItem.value">
                    <input type="checkbox" class="custom-control-input" id="chkKeepTrackItem"
                           [attr.disabled]="true" formControlName="isTrackedItem"
                           (change)="onTrackItem();" *ngIf="!f.canEditTrackedItem.value">
                    <label class="custom-control-label" for="chkKeepTrackItem">{{'InventoryModule.Item.Keep track of this item' | translate}}
                    </label>
                </div>
            </div><small class="help-block small text-muted">
                {{'InventoryModule.Item.Tracking item allows you to view its stock based on the sales and purchase transaction recorded fot it, and prevent you from selling below a quantity of zero.' | translate}}
            </small>
        </div>
        <div class="col-md-2">
            <label class="col-form-label" for="inventoryAssetAccountID">{{'InventoryModule.Item.Inventory Asset Account' | translate}}</label>
            <ng-select [items]="lstInventoryAssAc" class="auto-grow"
                       *ngIf="f.canEditTrackedItem.value" [readonly]="(!f.isTrackedItem.value || CanEditInventoryAccount) ? true:false"
                       bindLabel="DisplayAccountName" bindValue="CAAccountID"
                       groupBy="AccountGroupName" formControlName="inventoryAssetAccountID"
                       [ngClass]="{ 'is-invalid': submitted && f.inventoryAssetAccountID.errors }">
                <ng-template ng-optgroup-tmp let-item="item">
                    {{item.AccountGroupName || 'Unnamed group'}}
                </ng-template>
            </ng-select>
            <ng-select [items]="lstInventoryAssAc" class="auto-grow"
                       *ngIf="!f.canEditTrackedItem.value"
                       [readonly]="(!f.isTrackedItem.value || CanEditInventoryAccount) ? true:false" bindLabel="DisplayAccountName"
                       bindValue="CAAccountID" groupBy="AccountGroupName"
                       formControlName="inventoryAssetAccountID"
                       [ngClass]="{ 'is-invalid': submitted && f.inventoryAssetAccountID.errors }">
                <ng-template ng-optgroup-tmp let-item="item">
                    {{item.AccountGroupName || 'Unnamed group'}}
                </ng-template>
            </ng-select>
            <div *ngIf="submitted && f.inventoryAssetAccountID.errors" class="invalid-feedback">
                <div *ngIf="f.inventoryAssetAccountID.errors.required">{{'Common.Is required' | translate}}
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <label class="col-form-label" for="openingBalanceUnitPrice">{{'InventoryModule.Item.Opening Cost Per Unit' | translate}}</label>
            <input type="text" class="form-control" maxlength="15" quantityDecimal4 
                   onpaste="return false;" formControlName="openingBalanceUnitPrice"
                   (input)="formConditionalRequired();"
                   [ngClass]="{ 'is-invalid':submitted && f.openingBalanceUnitPrice.errors }" />
            <div *ngIf="submitted && f.openingBalanceUnitPrice.errors" class="invalid-feedback">
                <div *ngIf="f.openingBalanceUnitPrice.errors.pattern">
                    {{'InventoryModule.Item.Please provide valid opening cost per unit.' | translate}}</div>
                <div *ngIf="f.openingBalanceUnitPrice.errors.required">
                    {{'InventoryModule.Item.Opening Cost Per Unit allow only greater than or equal to 0.' | translate}}
                </div>
                <div *ngIf="f.openingBalanceUnitPrice.errors.max">
                    {{'InventoryModule.Item.Opening Cost Per Unit should be 0 or blank when Opening Stock is zero.' | translate}}
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <label class="col-form-label" for="openingBalanceUnit">{{'InventoryModule.Item.Opening Stock' | translate}}</label>
            <input type="text" class="form-control" maxlength="16" quantityDecimal4 *ngIf="this.inventoryForm.controls.enableNegativeStock.value==false"
                   onpaste="return false;" formControlName="openingBalanceUnit"
                   (input)="formConditionalRequired();"
                   [ngClass]="{ 'is-invalid': submitted && f.openingBalanceUnit.errors }" />
            <input type="text" class="form-control" maxlength="16" digit10 *ngIf="this.inventoryForm.controls.enableNegativeStock.value==true"
                   onpaste="return false;" formControlName="openingBalanceUnit" [ngClass]="{ 'is-invalid': submitted && f.openingBalanceUnit.errors }"
                   (input)="formConditionalRequired();" />
            <div *ngIf="f.openingBalanceUnit.errors" class="invalid-feedback">
                <div *ngIf="f.openingBalanceUnit.errors.pattern">
                    {{'InventoryModule.Item.Please provide valid opening stock.' | translate}}</div>
            </div>
            <div *ngIf="submitted && f.openingBalanceUnit.errors" class="invalid-feedback">
                <div *ngIf="f.openingBalanceUnit.errors.required">{{'Common.Is required' | translate}}
                </div>
                <div *ngIf="f.openingBalanceUnit.errors.min && this.inventoryForm.controls.enableNegativeStock.value==false">
                    {{'InventoryModule.Item.Opening Stock allow only greater then or equal 0.' | translate}}
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <label class="col-form-label" for="openingBalanceDate">{{'InventoryModule.Item.Opening Balance Date' | translate}}</label>
            <input class="form-control" placeholder="dd/mm/yyyy" name="openingBalanceDate" date
                   autocomplete="off" id="openingBalanceDate" formControlName="openingBalanceDate"
                   ngbDatepicker #d2="ngbDatepicker"
                   [ngClass]="{ 'is-invalid': f.openingBalanceDate.errors }" readonly>
            <div *ngIf="f.openingBalanceDate.errors" class="invalid-feedback">
                <div *ngIf="f.openingBalanceDate.errors.required">
                    {{'InventoryModule.Item.Please add Migration Date in Migration -> Opening balance' | translate}}
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <label class="col-form-label" for="minimumQuantity">{{'InventoryModule.Item.Alert Minimum Qty' | translate}}</label>
            <input type="text" class="form-control" maxlength="16" Decimal10
                   [readonly]="f.isTrackedItem.value==true?false:true"
                   formControlName="minimumQuantity" />
        </div>
        <div class="col-md-2">
            <label class="col-form-label" for="AvgCostTypeID">{{'InventoryModule.Item.Costing Method' | translate}}</label>
            <ng-select formControlName="avgCostTypeID" [items]="lstCostingType" bindValue="avgCostTypeID"
                       bindLabel="avgCostTypeName" [clearable]="false" [clearOnBackspace]="false"
                       [readonly]="!f.isTrackedItem.value || isCostingOptionDisabled" [ngClass]="{ 'is-invalid': submitted && f.avgCostTypeID.errors }">
            </ng-select>
            <div *ngIf="submitted && inventoryForm.controls.avgCostTypeID.errors" class="invalid-feedback">
                <div *ngIf="inventoryForm.controls.avgCostTypeID.errors.required">{{'Common.Is required' | translate}}
                </div>
            </div>
        </div>
        <ng-container *userCanApprove="[true]">
            <div class="col-md-12" *ngIf="isNegativeStockEnabled" >
                <div class="form-title">
                    <label class="col-form-label">{{'' | translate}}</label>
                    <div class="custom-control-mid custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="chkEnableNegativeStock"
                            (change)="onChangeNegativeStock($event);" formControlName="enableNegativeStock">
                        <label class="custom-control-label h2" for="chkEnableNegativeStock">{{'InventoryModule.Item.Enable Negative Stock' | translate}}
                            <a type="button" triggers="mouseenter:mouseleave" [popover]="popSpentTemplateHtml"
                            popoverTitle="{{'InventoryModule.Item.Info on Negative Stock' | translate}}" class="btn m-0 ml-1 p-0">
                                <i class="fa fa-info-circle"></i></a>
                        </label>
                        <div class="text-danger"><small> {{'SettingsModule.Negative Stock Note' | translate}}</small></div>
                        <ng-template #popSpentTemplateHtml>
                            <div>
                                {{'InventoryModule.Item.Info on Negative Stock-info'| translate }}
                            </div>
                        </ng-template>

                    </div>
                </div>
            </div>
        </ng-container>
    </div>
                            
                            <!-- <div class="col-md-12">
                                <div class="form-title h2">
                                    <div class="custom-control-mid custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="EnableAdvTracking"
                                            formControlName="EnableAdvTracking">
                                        <label class="custom-control-label" for="EnableAdvTracking">Enable Advanced
                                            Tracking
                                        </label>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="">
                                        <input type="radio" [value]="defatulSelection" [checked]="true" formControlName="BatchTrackingType">
                                        <label>Batch Tracking</label>
                                    </div>
                                    <div class="">
                                        <input type="radio" value="serialTracking" formControlName="BatchTrackingType">
                                        <label>Serial Number Tracking</label>
                                    </div> 
                                </div>
                            </div> -->
                            <div class="row mt-3" *ngIf="f.itemTypeID.value==1">
                                <div class="col-md-3" *ngIf="enableAdvInventory">
                                    <div class="form-title h2">
                                        <div class="custom-control-mid custom-checkbox">
                                            <input type="checkbox"
                                                class="custom-control-input" formControlName="enableAdvTracking"
                                                id="chkEnableAdvTracking" (change)="onChangeAdvanceTracking($event)">
                                            <label class="custom-control-label" for="chkEnableAdvTracking">
                                                {{'InventoryModule.Item.Enable Advanced Tracking' | translate}}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <mat-radio-group formControlName="advancedInventoryTrackingType">
                                            <div class="col-md-6 col-form-label">
                                                <mat-radio-button value="batchTracking" [disabled]="isRadioButtonDisable">{{'InventoryModule.Item.Batch Tracking' | translate}}
                                                </mat-radio-button>
                                            </div>
                                            <div class="col-md-6 col-form-label">
                                                <mat-radio-button value="serialTracking" [disabled]="isRadioButtonDisable">{{'InventoryModule.Item.Serial Number Tracking' | translate}}</mat-radio-button>
                                            </div>
                                        </mat-radio-group>                                       
                                    </div>
                                </div>
                                <div class="col-md-2" *ngIf="isEnableWarehouse">
                                    <label class="col-form-label" for="warehouseID">{{'Warehouse' | translate}}</label>
                                    <ng-select formControlName="warehouseID" [items]="lstWareHouse" bindValue="WarehouseId" bindLabel="WarehouseName"
                                        [clearable]="false" [clearOnBackspace]="false" [ngClass]="{ 'is-invalid': submitted && f.warehouseID.errors }">
                                    </ng-select>
                                    <div *ngIf="submitted && f.warehouseID.errors" class="invalid-feedback">
                                        <div *ngIf="f.warehouseID.errors.required">{{'Common.Is required' | translate}}
                                        </div>
                                    </div>
                                </div>  
                            </div>
                           </div>
                        </ng-container>
                            <div class="custom-ag-grid-5" [style.display]="f.itemTypeID.value==3 ? 'block' : 'none'">
                                <ag-grid-angular style="width: 100%;" class="ag-theme-alpine" [modules]="modules"
                                    [columnDefs]="groupcolumnDefs" [singleClickEdit]="true" rowSelection="multiple"
                                    [rowData]="groupData" (gridReady)="onprogroupGridReady($event)"
                                    [gridOptions]="progroupGridOptions" [frameworkComponents]="frameworkComponents"
                                    [stopEditingWhenGridLosesFocus]="true"
                                    (cellValueChanged)="ongroupCellValueChanged($event)"
                                    (selectionChanged)="onSelectionChanged($event)" [suppressRowClickSelection]="true"
                                    (cellClicked)="ongroupCellClicked($event)" [domLayout]="domLayout">
                                </ag-grid-angular>

                                <div *userCanEdit="[true]" class="btn-group" dropdown>
                                    <button type="button" (click)="addRows(1,'A')" class="btn btn-info btn-normal">{{'InventoryModule.Item.Add New Lines' | translate}}</button>

                                    <button type="button" dropdownToggle
                                        class="btn btn-info dropdown-toggle dropdown-toggle-split">
                                        <span class="caret"></span>
                                        <span class="sr-only">Split button!</span>
                                    </button>
                                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                                        <span *ngFor="let n of addNumberOfRows">
                                            <li role="menuitem"><a (click)="addRows(n,'A')" class="dropdown-item">{{'InventoryModule.Item.Add' | translate}} {{
                                                    n }}</a></li>
                                        </span>
                                    </ul>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="btn-group" dropdown *ngIf="callFrom=='AN'">
                    <button [disabled]="itemloading" class="btn btn-success" type="button" *userCanEdit="[true]"
                        (click)="onSubmit('save')">
                        <span *ngIf="itemloading" class="spinner-border spinner-border-sm mr-1"></span> {{'Common.Save' | translate}}
                    </button>

                    <button type="button" dropdownToggle class="btn btn-success dropdown-toggle dropdown-toggle-split">
                        <span class="caret"></span>
                        <span class="sr-only">Split button!</span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu split-dropup-top" role="menu">
                        <li role="menuitem"><a (click)="onSubmit('savenaddanother')" class="dropdown-item">{{'InventoryModule.Item.Save & add another' | translate}}</a></li>
                    </ul>
                </div>
                <ng-container *ngIf="callFrom!='AN'">
                    <button [disabled]="itemloading" class="btn btn-success" type="button" *userCanEdit="[true]"
                        (click)="onSubmit('save')">
                        <span *ngIf="itemloading" class="spinner-border spinner-border-sm mr-1"></span> {{'Common.Save' | translate}}
                    </button>
                </ng-container>
                <button class="btn btn-danger" type="button" (click)="hideaddItemmodal()">{{'Common.Cancel' | translate}}</button>
            </div>
        </div>
    </div>
</div>
<app-addtextdesc (toParentAfterAdd)="returnTextDescription($event)"></app-addtextdesc>
<div class="modal" id="cropperModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-body image-popup-content">
          <img class="image-popup" id="imageToCrop" [src]="imageSrc" alt="Image to crop" />
        </div>
        <div class="modal-footer">
            <div class="row">
                <div class="col-md-9">
                    <div class="help-block text-warning"> 
                        <b>{{'Note:'| translate}}</b>
                        {{'Easily adjust your image by dragging to reposition both the image and cropping area, and zooming in or out for a more precise selection. Double-click to switch between drag modes for added flexibility.' | translate}}
                    </div>
                </div>
                <div class="col-md-3">
                    <button type="button" class="btn btn-primary" (click)="cropImage()">Crop</button>
                    <button type="button" class="btn btn-secondary" (click)="closeCropImage()">Close</button>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
