import { Injectable } from '@angular/core';
import { ELTreeNode } from "../../shared/report-edit-layout/edit-layout-node.service";
import { boolean } from 'mathjs';

@Injectable({
    providedIn: 'root'
})

export class TreeviewService {

    dynamicColumns = [];
    isLoadFromLayout = false;
    rowNo = 0;

    constructor() { }

    prepareNodeData(Nodedata, Sort, dynamicColumns) {
        this.dynamicColumns = dynamicColumns;
        let nodeData = Nodedata.filter(res => Number(res.ParentGroupID) == 0 && !res.IsFormula && !res.IsTotalRow);
        let nodeAccountsData = Nodedata.filter(res => Number(res.ParentGroupID) == 0 && (res.IsFormula));
        nodeAccountsData = nodeAccountsData.map(r => {
            if (r.IsFormula) {
                // r.AccountName = r.GroupName;
                r.AccountCode = r.GroupName;
            }
            r.AccDisplayOrder = r.DisplayOrder,
                r.RowLevel = 1;
            r.IsNode = false;
            r.RowType = 'subtotal'
            this.rowNo += 1;
            r.RowNo = this.rowNo;
            return r;

        })
        let parentnodeData = [];
        nodeData.forEach(res => {
            parentnodeData.push({
                GroupID: res.GroupID,
                IsExpanded: res.IsExpanded,
                IsTotalRequired: res.IsExpanded,
                Children: [],
                AccountType: null,
                GroupName: res.GroupName,
                IsNode: true,
                ParentGroupID: 0,
                IsDebit: res.IsDebit,
                IsCredit: res.IsCredit,
                AccountId: null,
                IsDragable: res.IsDragable,
                RowLevel: 1,
                LayoutId: res.LayoutID,
                LayoutName: res.LayoutName,
                IsFormula: res.IsFormula,
                Formula: res.Formula,
                FormulaDesc: res.FormulaDesc,
                // AccountName: res.GroupName,
                AccountCode: res.GroupName,
                AccDisplayOrder: res.DisplayOrder,
                RowType: 'Header',
                level: 1,
                ParentCAAccountID: res.ParentCAAccountID
            })
        });

        let parentnodearrayUniqueByKey = [];
        let i = 0;
        parentnodeData.forEach(r => {
            let data = parentnodearrayUniqueByKey.filter(res => res.GroupID == r.GroupID);
            if (data.length == 0) {
                this.rowNo += 1;
                r.RowNo = this.rowNo;
                parentnodearrayUniqueByKey.push(r);
            }
        })
        parentnodearrayUniqueByKey = [...parentnodearrayUniqueByKey, ...nodeAccountsData]
        parentnodearrayUniqueByKey = parentnodearrayUniqueByKey.sort((a, b) => a.AccDisplayOrder - b.AccDisplayOrder);
        return this.prepareNode(parentnodearrayUniqueByKey, Nodedata, 0, Sort);
    }

    prepareNode(node, Children, ParentGroupID, Sort) {
        let nodeData = [];
        node.forEach(res => {
            if (res.IsNode) {
                let childGroups = Children.filter(N => N.ParentGroupID == res.GroupID && !res.IsTotalRow);
                let childAccounts = Children.filter(N => N.GroupID == res.GroupID && N.CAAccountID != null);
                let TotalAccounts = Children.filter(N => N.GroupID == res.GroupID && N.IsTotalRow && N.CAAccountID == null);
                TotalAccounts = TotalAccounts.map(element => {
                    element.AccountCode = element.GroupName;
                    element.RowLevel = res.RowLevel + 1;
                    element.RowType = 'subtotal'
                    this.rowNo += 1;
                    element.RowNo = this.rowNo;
                    return element;
                });
                let childElement = []
                childAccounts.forEach(element => {
                    element.IsNode = false;
                    element.RowLevel = res.RowLevel + 1;
                    element.ParentGroupID = ParentGroupID;
                    element.RowType = ''
                    this.rowNo += 1;
                    element.RowNo = this.rowNo;
                    childElement.push(element);
                });

                let parentnodeData = [];
                childGroups.forEach(p => {

                    if (res.GroupID != p.GroupID) {
                        let elTreeNode = new ELTreeNode();

                        elTreeNode.Children = [];
                        elTreeNode.LayoutID = p.LayoutID;
                        elTreeNode.LayoutName = p.LayoutName;
                        elTreeNode.GroupID = p.GroupID;
                        elTreeNode.GroupName = p.GroupName;
                        elTreeNode.ParentGroupID = res.GroupID;
                        elTreeNode.DisplayOrder = p.DisplayOrder;
                        elTreeNode.IsDragable = p.IsDragable;
                        elTreeNode.IsExpanded = p.IsExpanded;
                        elTreeNode.IsTotalRequired = p.IsTotalRequired;
                        elTreeNode.IsDebit = p.IsDebit;
                        elTreeNode.IsCredit = p.IsCredit;
                        elTreeNode.IsFormula = p.IsFormula;
                        elTreeNode.Formula = p.Formula;
                        elTreeNode.FormulaDesc = p.FormulaDesc;
                        elTreeNode.CAAccountID = null;
                        elTreeNode.AccountCode = null;
                        elTreeNode.SystemCode = null;
                        elTreeNode.AccountCode = p.GroupName;
                        elTreeNode.AccountTypeID = null;
                        elTreeNode.AccDisplayOrder = p.DisplayOrder;
                        elTreeNode.AccountType = null;
                        elTreeNode.IsNode = true;
                        elTreeNode.RowLevel = res.RowLevel + 1;
                        elTreeNode.level = 0;
                        elTreeNode.RowType = 'Header'
                        parentnodeData.push(elTreeNode);
                    }
                });

                let parentnodearrayUniqueByKey = [];
                parentnodeData.forEach(r => {
                    let data = parentnodearrayUniqueByKey.filter(x => x.GroupID == r.GroupID);
                    if (data.length == 0) {
                        this.rowNo += 1;
                        r.RowNo = this.rowNo;
                        parentnodearrayUniqueByKey.push(r);
                    }
                })

                let groupAccCombined = [...parentnodearrayUniqueByKey, ...childAccounts];

                if (groupAccCombined.length > 0) {
                    if (groupAccCombined[0].RowType != 'Header') {
                        this.sortStrings(groupAccCombined, Sort.active);
                        if (Sort.direction == 'desc')
                            groupAccCombined.reverse();
                    }
                    if (Sort.active == "AccDisplayOrder")
                        res.Children = groupAccCombined.sort((a, b) => a.AccDisplayOrder - b.AccDisplayOrder);
                    else
                        res.Children = groupAccCombined;
                }
                else {
                    res.Children = [];
                }

                if (res.Children.filter(r => !r.IsTotalRow).length == 0) {
                    //   res.AccountName = res.AccountName.replace('Total', '');
                    res.AccountCode = res.AccountCode.replace('Total', '');
                }

                if (res.IsTotalRequired && res.IsNode) {
                    if (TotalAccounts.length > 0) {
                        res.Children.push(TotalAccounts[0]);
                    }
                }
                if (groupAccCombined.filter(x => x.IsNode).length > 0) {
                    res.Children = Object.assign([], this.prepareNode(res.Children, Children, res.GroupID, Sort));
                }

                if ((res.level == 0 || res.level == 1) && res.RowType == "Header") {
                    this.dynamicColumns.forEach(col => {
                        if (res.Children?.length > 0) {
                            res[col] = res.Children.at(-1)[col];
                        }

                    })
                }

            }
            nodeData.push(res);
        })
        return nodeData;
    }

    swap(list: any[], i1: number, i2: number) {
        let temp = list[i1];
        list[i1] = list[i2];
        list[i2] = temp;
    }

    sortStrings(list: any[], field: string) {
        for (let i1 = 0; i1 < list.length; i1++) {
            for (let i2 = i1 + 1; i2 < list.length; i2++) {
                if (field == 'AccDisplayOrder') {
                    if ((list[i1][field] ?? 0) > (list[i2][field] ?? 0)) {
                        this.swap(list, i1, i2)
                    }
                }
                else {
                    if ((list[i1][field] ?? '').toString().toLowerCase() > (list[i2][field] ?? '').toString().toLowerCase()) {
                        this.swap(list, i1, i2)
                    }
                }
            }
        }
    }

    getFlatGroupData(data, isLoadFromLayout){
       this.isLoadFromLayout = isLoadFromLayout;
       return this.flatGroup(data);
    }

    flatGroup(data) {
        let FlatData = [];
        data.forEach(res => {
          FlatData.push(res);
    
          let Children = [];
          if (res.Children?.length > 0) {
            if (res.IsExpanded || this.isLoadFromLayout) {
              Children = this.flatGroup(res.Children)
            } else {
              let totalRow = res.Children.filter(x => x.IsTotalRow);
              if (totalRow.length > 0) {
                this.dynamicColumns.forEach(dyCol => {
                  res[dyCol] = totalRow[0][dyCol];
                })
              }
            }
            if (Children.length > 0) {
              FlatData = [...FlatData, ...Children];
            }
          }
        })
        return FlatData;
    }

    // Expanded Rows With Collapsed Headers
    getExpandedRows(treeControl, exportdata: any[], loadFromLayout) {
        let collapsedAccounts = treeControl.dataNodes
            .filter(row => !!row.expandable && !treeControl.isExpanded(row))
            .map(row => row.data);
        let flatCollapsedAccounts = this.getFlatGroupData(collapsedAccounts, loadFromLayout);
        let expandedAccounts = [];
        exportdata.forEach(row => {
            let collapsedGroup = collapsedAccounts.find(crow => crow == row);
            let isChildOfCollapsedHeader = flatCollapsedAccounts.filter(frow => frow == row).length > 1;
            if (collapsedGroup && row.RowType == 'Header' && !isChildOfCollapsedHeader) {
                let currentRow = { ...row, Collapsed: true };
                expandedAccounts.push(currentRow);
            } else {
                if (flatCollapsedAccounts.find(frow => frow == row) == undefined) {
                    expandedAccounts.push(row);
                }
            }
        });
        return expandedAccounts;
    }

    expandAll(datas, treeControl) {
        if (treeControl) {
            const expandedDataNodes = treeControl.dataNodes.filter(node => datas.find(data => data == node.data)?.IsExpanded);
            expandedDataNodes.forEach(flatNode => treeControl.expand(flatNode));
        }
    }
}
