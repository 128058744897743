import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { User } from '../_models/user';
import { LocalCacheServices } from './acclocalcache.service'
import { EncryptDecryptService } from './encryptdecrypt.service'
import { HttpServices } from '../../utilities/_services/http.service';
import { PageServices } from '../../utilities/_services/page.service';
import { v4 as uuid } from 'uuid';
import { Idle } from '@ng-idle/core';
import { MatDialog } from '@angular/material/dialog';
import { HostService } from './host.serices';
import { AccsessioncacheService } from './accsessioncache.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  us: any;
  private refreshTokenTimeout: any;

  idleState = 'Not started.';
  timedOut = false;
  hostName: string;
  isDigisme = false;
  constructor(private http: HttpClient, private router: Router, private local: LocalCacheServices,
    private encdec: EncryptDecryptService, private custHttp: HttpServices, private hostService: HostService,
    private idle: Idle, private page: PageServices, private dialogRef: MatDialog, private session: AccsessioncacheService) {
    if (localStorage.getItem(this.local.localCurrentUserKey) != null) {
      try {
        this.us = this.encdec.decode(localStorage.getItem(this.local.localCurrentUserKey));
      } catch (error) {
        localStorage.clear();
        this.us = null;
      }
    }
    else {
      this.us = null;
    }
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(this.us));
    this.currentUser = this.currentUserSubject.asObservable();
    this.hostName = this.hostService.getHostname();
    this.isDigisme = this.hostService.getDigisme();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  clearUserSubject() {
    this.stopRefreshTokenTimer();
    localStorage.removeItem(this.local.localCurrentUserKey);
    this.currentUserSubject.next(null);
  }

  GetCustomersbySearchText(SearchText: string) {
    let params = new HttpParams();
    if (typeof (SearchText) == "string" && SearchText.trim() != '') {
      params = params.set('SearchText', SearchText.toString());
      params = params.set('IsDigiSME', this.isDigisme.toString());
      let data = this.http.post(environment.administrationApiUrl + '/Customer/GetCustomersbySearchText', params);
      return data;
    }
    else {
      params = params.set('SearchText', "--select--");
      params = params.set('IsDigiSME', this.isDigisme.toString());
      let data = this.http.post(environment.administrationApiUrl + '/Customer/GetCustomersbySearchText', params);
      return data;
    }
  }

  async GetSupportorNormalUser(username: string, password: string, DeviceUID: string, DeviceName: string,IsdigiSME: boolean) {
    let body = {
      username,
      password,
      DeviceUID,
      DeviceName,
      Platform: "Web",
      IsDigiSME:IsdigiSME
    };
    let response = await this.http.post(environment.authguardApiUrl + '/AuthLogin/GetSupportorNormalUser', body).toPromise();
    return response;
  }

  async ValidateOTP(otpID: string, otp: string) {
    let params = new HttpParams();
    params = params.set('otpID', otpID);
    params = params.set('otp', otp);
    let response = await this.http.post(environment.authguardApiUrl + '/TwoFactorAuthentication/ValidateOTP', params).toPromise();
    return response;
  }

  async ReSendOTP(LoginUserID: number, Username: string, OrgID: number, CustomerCode: string, DeviceUID: string, DeviceName: string) {
    let body = {
      OrgID,
      LoginUserID,
      Username,
      CustomerCode,
      DeviceUID,
      DeviceName,
      Platform: "Web",
    };
    let response = await this.http.post(environment.authguardApiUrl + '/TwoFactorAuthentication/ReSendOTP?OTP_Type=2', body).toPromise();
    return response;
  }

  login(centralUserID: any, issupportUser: any, userType: any) {
    let  isDigisme =  this.isDigisme
    const browseruuid: string = this.encdec.encodeBase64(uuid());
    return this.http.post<any>(environment.authguardApiUrl + '/AuthLogin/login', { centralUserID, issupportUser, userType, browseruuid, isDigisme })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem(this.local.localCurrentUserKey, this.encdec.encode(user));
        //if(user.licenseTypeID == 6){
        //  this.sendCRMUpdateLogin(user.customerCode);
        //}
        this.local.getLocalStorageSize();
        this.currentUserSubject.next(user);
        this.startRefreshTokenTimer();
        return user;
      }));
  }

  sendCRMUpdateLogin(CustomerCode) {
    let obj =    {
      "AccountingLastLogin": "YES",
      "HrmsLastLogin": "",
      "CustomerCode": CustomerCode
    }
    this.http.post<any>(environment.disismeCRMApiUrl + '/Customer/UpdateLastLogin', obj).subscribe(r => {})
  }

  changeOrganization(centralOrgID: number, customerCode: number) {
    let params = new HttpParams();
    params = params.set('custCode', (customerCode > 0 ? customerCode : this.currentUserValue.customerCode).toString());
    params = params.set('centralOrgID', centralOrgID.toString());
    params = params.set('browseruuid', this.currentUserValue.browseruuid.toString());
    params = params.set('IssupportUser', this.currentUserValue.issupportUser.toString());
    params = params.set('UserType', this.currentUserValue.userType.toString());
    params = params.set('IsDigisme', this.isDigisme.toString());

    return this.http.post<any>(environment.authguardApiUrl + '/AuthLogin/changeOrganization', params)
      .pipe(map(user => {
        this.local.refreshChangeOrg();
        this.session.removesession();
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem(this.local.localCurrentUserKey, this.encdec.encode(user))
        this.local.getLocalStorageSize();
        this.currentUserSubject.next(user);
        this.startRefreshTokenTimer();

        return user;
      }));
  }

  logoutwithoutRouting() {
    this.idle.stop();
    this.idleState = 'logout'
    console.log(this.idleState, " : ", new Date(), " : ", this.router.url);
    if (this.currentUserValue == null || this.currentUserValue == undefined &&
      this.currentUserValue.refreshToken == null || this.currentUserValue.refreshToken == undefined) {
      let params = new HttpParams();
      params = params.set('refreshToken', btoa(this.currentUserValue.refreshToken));
      params = params.set('IsSupportUser', this.currentUserValue.issupportUser.toString());
      params = params.set('UserType', this.currentUserValue.userType.toString());
      this.http.post<any>(environment.authguardApiUrl + '/AuthLogin/revoketoken', params).subscribe({
        next: (res) => {
          this.stopRefreshTokenTimer();
          // remove user from local storage to log user out
          this.local.removeLocalCache();
          this.currentUserSubject.next(null);
        },
        error: (res) => {
          this.stopRefreshTokenTimer();
          // remove user from local storage to log user out
          this.local.removeLocalCache();
          this.currentUserSubject.next(null);
        }
      });
    }
    else {
      this.stopRefreshTokenTimer();
      // remove user from local storage to log user out
      this.local.removeLocalCache();
      this.currentUserSubject.next(null);
    }
  }

  logouttourl() {
    this.stopRefreshTokenTimer();
    this.local.removeLocalCache();
    this.currentUserSubject.next(null);
    this.hostService.logout();
  }

  logout() {
    this.idle.stop();
    this.idleState = 'logout'
    console.log(this.idleState, " : ", new Date(), " : ", this.router.url);

    this.dialogRef.closeAll();


    if (this.currentUserValue != null && this.currentUserValue != undefined &&
      this.currentUserValue.refreshToken != null && this.currentUserValue.refreshToken != undefined) {
      let params = new HttpParams();
      params = params.set('refreshToken', btoa(this.currentUserValue.refreshToken));
      this.http.post<any>(environment.authguardApiUrl + '/AuthLogin/revoketoken', params).subscribe({
        next: (res) => {
          // remove user from local storage to log user out
          this.logouttourl();
        },
        error: (res) => {
          // remove user from local storage to log user out
          this.logouttourl();
        }
      });
    }
    else {
      // remove user from local storage to log user out
      this.logouttourl();
    }
  }

  async refreshToken() {
    let timeout = this.getTokenTime();
    if (timeout > 0) {
      let params = new HttpParams();
      params = params.set('refreshToken', btoa(this.currentUserValue.refreshToken));
      params = params.set('custCode', this.currentUserValue.customerCode.toString());
      params = params.set('IssupportUser', this.currentUserValue.issupportUser.toString());
      params = params.set('UserType', this.currentUserValue.userType.toString());
      return await this.http.post<any>(environment.authguardApiUrl + '/AuthLogin/refreshtoken', params)
        .subscribe({
          next: (user) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(this.local.localCurrentUserKey, this.encdec.encode(user))
            this.local.getLocalStorageSize();
            this.currentUserSubject.next(user);
            this.startRefreshTokenTimer();
            return user;
          },
          error: (res) => {
            // remove user from local storage to log user out
            this.logouttourl();
          }
        });
    }
    else {
      this.logouttourl();
    }
  }

  // helper methods
    public startRefreshTokenTimer() {
        this.stopRefreshTokenTimer();
        if (this.currentUserValue != null && this.currentUserValue != undefined) {
            if (this.currentUserValue.accessToken != null && this.currentUserValue.accessToken != undefined) {
                // parse json object from base64 encoded jwt token
                const jwtToken = JSON.parse(atob(this.currentUserValue.accessToken.split('.')[1]));
                // set a timeout to refresh the token a minute before it expires
                const expires = new Date(jwtToken.exp * 1000);
                const timeUntilExpiration = expires.getTime() - Date.now() - (120 * 1000);
                if (timeUntilExpiration > 0) {
                    this.refreshTokenTimeout = timer(timeUntilExpiration).subscribe(() => {
                        console.error("Refresh Token started inside the timer");
                        this.refreshToken();
                    });
                } else {
                    this.refreshToken();
                }
            }
        }
    }

    public stopRefreshTokenTimer() {
        if (this.refreshTokenTimeout) {
            this.refreshTokenTimeout.unsubscribe();
        }
    }

  public getTokenTime() {
    if (this.currentUserValue == null || this.currentUserValue == undefined) {
      return 0;
    }
    else {
      // parse json object from base64 encoded jwt token
      const jwtToken = JSON.parse(atob(this.currentUserValue.accessToken.split('.')[1]));
      const expires = new Date(jwtToken.exp * 1000);
      const timeout = expires.getTime() - Date.now();
      return timeout / 1000;
    }
  }

  async VerifyKey(key: any) {
    let params = new HttpParams();
    params = params.set('key', key);
    let response = await this.http.post(environment.authguardApiUrl + '/AuthLogin/verifykey', params).toPromise();
    return response;
  }

  async SaveVerifyChange(key: any, Password: any) {
    let params = new HttpParams();
    params = params.set('key', key);
    params = params.set('Password', this.encdec.encodeBase64(Password));
    let response = await this.http.post(environment.authguardApiUrl + '/AuthLogin/saveverifychange', params).toPromise();
    return response;
  }

  async ForgotPassword(UserName: any, LicenseTypeID: any) {
    let params = new HttpParams();
    params = params.set('UserName', this.encdec.encodeBase64(UserName));
    params = params.set('LicenseTypeID', LicenseTypeID);
    let response = await this.http.post<any>(environment.authguardApiUrl + '/AuthLogin/forgotpassword', params);
    return response.pipe(map(user => { return user; }));
  }

  async ChangePassword(Password: any, CurrentPassword: any) {
    let params = new HttpParams();
    params = params.set('Password', this.encdec.encodeBase64(Password));
    params = params.set('CurrentPassword', this.encdec.encodeBase64(CurrentPassword));
    let response = await this.http.post(environment.authguardApiUrl + '/AuthLogin/changepassword', params).toPromise();
    return response;
  }


  async GetMaintanenceNotice(isDigisme:boolean) {
    let response = await this.http.get<any>(environment.authguardApiUrl + '/Anonymous/GetMaintanenceNote_FromBlob?isDigisme='+ isDigisme).toPromise();
    console.log(response);
    return response;
  }

  async GetUserList() {
    let response = await this.http.get<any>(environment.authguardApiUrl + '/UserMaster/GetUserList').toPromise();
    return response;
  }

  async GetUserLoginHistory() {
    let response = await this.http.get<any>(environment.authguardApiUrl + '/LoginHistory/GetLoginHistory').toPromise();
    return response;
  }

  async SaveUpdateUserMaster(olduserMstr: any, newuserMstr: any) {
    let params = new HttpParams();
    params = params.set('olduserMstr', JSON.stringify(olduserMstr));
    params = params.set('newuserMstr', JSON.stringify(newuserMstr));
    params = params.set('PageID', this.page.pageDetails.pageID.toString());
    let response = await this.http.post(environment.authguardApiUrl + '/UserMaster/SaveUpdateUserMaster', params);
    return response;
  }

  async InviteUser(userMstr: any, message: any) {
    let params = new HttpParams();
    params = params.set('userMstr', JSON.stringify(userMstr));
    params = params.set('message', message);
    params = params.set('PageID', this.page.pageDetails.pageID.toString());
    let response = await this.http.post(environment.authguardApiUrl + '/UserMaster/InviteUser', params);
    return response;
  }

  async InviteUserSub(userMstr: any, message: any, subscriberID: any) {
    let params = new HttpParams();
    params = params.set('userMstr', JSON.stringify(userMstr));
    params = params.set('message', message);
    params = params.set('PageID', this.page.pageDetails.pageID.toString());
    params = params.set('subscriberID', subscriberID);
    let response = await this.http.post(environment.authguardApiUrl + '/UserMaster/InviteUser', params);
    return response;
  }

  async ResendInviteMail(userMstr: any) {
    let params = new HttpParams();
    params = params.set('userMstr', JSON.stringify(userMstr));
    params = params.set('PageID', this.page.pageDetails.pageID.toString());
    let response = await this.http.post(environment.authguardApiUrl + '/UserMaster/ResendInviteMail', params);
    return response;
  }

  async DeleteUserInvite(userMstr: any) {
    let params = new HttpParams();
    params = params.set('userMstr', JSON.stringify(userMstr));
    params = params.set('PageID', this.page.pageDetails.pageID.toString());
    let response = await this.http.post(environment.authguardApiUrl + '/UserMaster/DeleteUserInvite', params);
    return response;
  }

  internallogin(centralUserID: any, issupportUser: any, userType: any) {
    const browseruuid: string = this.encdec.encodeBase64(uuid());
    return this.http.post<any>(environment.authguardApiUrl + '/AuthLogin/internallogin', { centralUserID, issupportUser, userType, browseruuid })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem(this.local.localCurrentUserKey, this.encdec.encode(user))
        this.local.getLocalStorageSize();
        this.currentUserSubject.next(user);
        this.startRefreshTokenTimer();
        return user;
      }));
  }

  async refreshTokeninternallogin() {
    let timeout = this.getTokenTime();
    if (timeout > 0) {
      let params = new HttpParams();
      params = params.set('refreshToken', btoa(this.currentUserValue.refreshToken));
      params = params.set('IssupportUser', this.currentUserValue.issupportUser.toString());
      params = params.set('UserType', this.currentUserValue.userType.toString());
      return await this.http.post<any>(environment.authguardApiUrl + '/AuthLogin/refreshtokeninternallogin', params)
        .subscribe({
          next: (user) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(this.local.localCurrentUserKey, this.encdec.encode(user))
            this.local.getLocalStorageSize();
            this.currentUserSubject.next(user);
            this.startRefreshTokenTimer();
            return user;
          },
          error: (res) => {
            // remove user from local storage to log user out
            this.logouttourl();
          }
        });
    }
    else {
      this.logouttourl();
    }
  }

  async authenticateUserforClientApp(centralOrgID: number, customerCode: number, ClientID: any, token: any) {
    const _headers = new HttpHeaders().set('ClientID', ClientID).set('ClientToken', token);

    const myPostBody = {
      customerCode: (customerCode > 0 ? customerCode : this.currentUserValue.customerCode),
      centralOrgID: centralOrgID,
      browserUUID: this.currentUserValue.browseruuid,
      isSupportUser: this.currentUserValue.issupportUser,
      userType: this.currentUserValue.userType
    }

    console.log(myPostBody);

    let data = await this.http.post(environment.authguardApiUrl + '/AuthLogin/authenticateuserforclient',
      myPostBody, { headers: _headers });
    return data;
  }

  async generateToken(currentUser: any, ClientID: any, token: any) {
    const _headers = new HttpHeaders({ 'ClientID': ClientID, 'ClientToken': token });

    let data = await this.http.post(environment.authguardApiUrl + '/AuthLogin/saveusertokens',
      { accessToken: currentUser.accessToken, refreshToken: currentUser.refreshToken },
      { headers: _headers });
    return data;
  }

  clearlogouttourl() {
    this.stopRefreshTokenTimer();
    this.local.removeLocalCache();
    this.currentUserSubject.next(null);
  }
}
