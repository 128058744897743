import { AllCommunityModules, GridOptions, Module } from '@ag-grid-community/all-modules';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AgcolorcellrendererComponent } from '../../../shared/agrenderer/agcolorcellrenderer/agcolorcellrenderer.component';
import { CustomtooltipComponent } from '../../../shared/agrenderer/customtooltip/customtooltip.component';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AlertMessage } from '../../../../utilities/_helpers/alert.message';
import { BankaccountService } from '../../../../utilities/_services/bankaccount.service';
import { LocalCacheServices } from '../../../../utilities/_services/acclocalcache.service';

@Component({
  selector: 'app-viewstatement-reconcilation',
  templateUrl: './viewstatement-reconcilation.component.html',
  styleUrls: ['./viewstatement-reconcilation.component.scss']
})
export class ViewstatementReconcilationComponent implements OnInit {
  @ViewChild('showreconciliationmodal') public showreconciliationmodal: ModalDirective;
  @Output() sentToParent = new EventEmitter<String>();
  @Input() PageID: any;
  domLayout: string = "autoHeight";
  loading = false;
  currentUser: any;
  uarSM: any;
  uarRM: any;
  uarTM: any;
  showRmRdOps: boolean = false;
  CAAccountID:any;
  columnDefsStmtLines: any;
  columnDefsAdjustment: any;
  rowDataStmt: any[] = [];
  rowDataAdj: any[] = [];
  rowDataCount: number = 0;
  statusMsgId: any;
  rowData: any;
  selectedRowsCount:number=0;
  stmtLineGridOptions: GridOptions;
  adjGridOptions: GridOptions;
  frameworkComponents = {
      'colourCellRenderer': AgcolorcellrendererComponent,
      'customTooltip': CustomtooltipComponent
  };
  allGridOptions: GridOptions = <GridOptions>{
      onGridReady: (params: any) => {
        params.api.sizeColumnsToFit();
      },
      
      onPaginationChanged: () => (params: any) => {
        params.api.sizeColumnsToFit();
      }
  };
  overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Please wait while bank account transactions are loading</span>';
  modules: Module[] = AllCommunityModules;

  constructor(private translate: TranslateService,private local: LocalCacheServices,private router: Router,private alertMessage: AlertMessage,private bankService: BankaccountService,) { 
    this.currentUser = JSON.parse(this.local.getlocalStorage(this.local.localCurrentUserKey));
      this.uarSM = (this.currentUser.menuAccessRights.filter(x => x.pageURL == '/transactions/spendmoneyview' && x.accessRight == this.currentUser.userRole))[0] || null;
      this.uarRM = (this.currentUser.menuAccessRights.filter(x => x.pageURL == '/transactions/receivemoneyview' && x.accessRight == this.currentUser.userRole))[0] || null;
      this.uarTM = (this.currentUser.menuAccessRights.filter(x => x.pageURL == '/transactions/transfermoneyview' && x.accessRight == this.currentUser.userRole))[0] || null;
  }

  ngOnInit(): void {
  }


  onGridSizeChanged(params: any) {
    params.api.sizeColumnsToFit();
  }

  onAdjustmentCellClicked(params: any) {
    let transID = params.data.mappingTransactionID;
    let transTypeID = params.data.mappingTransactionTypeID;
    let caAccountID = params.data.caAccountID;
    let adjustmentTypeID = params.data.adjustmentTypeID;
    this.showreconciliationmodal.hide();
    const queryParams: any = {
      account: btoa(caAccountID),
      tranid: btoa(transID),
      trantypeid: btoa(transTypeID)
    };
    if(this.PageID){
      queryParams.pageid = btoa(this.PageID);
    }
    if (params.data.mappingTransactionTypeID == 10) {
      this.router.navigate(['/transactions/viewtransaction'], { queryParams });
    }
    else if (params.data.mappingTransactionTypeID == 11) {
      this.router.navigate(['/transactions/viewtransaction'], { queryParams });
    }
    else if (params.data.mappingTransactionTypeID == 9) {
      this.router.navigate(['/transactions/newtransfermoney'], { queryParams });
    }
    else if (params.data.mappingTransactionTypeID == 25) {
      //New screen link
      const queryParams: any = {
        tranid: btoa(transID), 
        reconcileId: btoa(transID), 
        trantypeid: btoa(transTypeID)
      };
      if(this.PageID){
        queryParams.pageid = btoa(this.PageID);
      }
      this.router.navigate(['/transactions/viewtransaction'], { queryParams });
    }
    else if (params.data.mappingTransactionTypeID == 14) {
      this.router.navigate(['accounting/journalentries/viewjournalentries'], { queryParams: { id: btoa(params.data.detailId), pageid: btoa(this.PageID ? this.PageID : '') } });
    }
  }

  


  createStmtColumnDefs() {
    this.columnDefsStmtLines = [
      {
        headerName: "Description",
        field: "stmtDescription",
        flex:1,
        cellClass:'text-left',
        headerValueGetter:(params)=>this.translate.instant("Common.Description")
      },
      {
        headerName: "Type",
        field: "type",
        width: 100,
        cellClass:'text-left',
        headerValueGetter:(params)=>this.translate.instant("BankingModule.BankAccounts.Type")
      },
      {
        headerName: "Spent",
        field: "spent",
        width: 100,
        cellClass:'text-right',
        headerClass: "ag-right-aligned-header",
        headerValueGetter:(params)=>this.translate.instant("BankingModule.BankAccounts.Spent")
      },
      {
        headerName: "Received",
        field: "received",
        width: 100,
        cellClass:'text-right',
        headerClass: "ag-right-aligned-header",
        headerValueGetter:(params)=>this.translate.instant("BankingModule.BankAccounts.Received")
      }
    ];

    this.columnDefsAdjustment = [
      {
        headerName: "Date",
        field: "date",
        sortable: true, width: 120,
        cellClass:'text-left',
        cellRenderer: (data) => {
          return moment(data.data.date).format('DD MMM YYYY')
        },
        headerValueGetter:(params)=>this.translate.instant("Common.Date")
      },
      {
        headerName: "Contact",
        field: "contact", sortable: true,
        flex:1,
        cellClass:'text-left',
        headerValueGetter:(params)=>this.translate.instant("Contact")
      },
      {
        headerName: "Spent",
        field: "spent",
        width: 150, sortable: true,
        cellRenderer: (params) => {
          if (params.value != null) {
            return '<a class="t-link edit">' + params.value + '</a>';
          }
        },
        cellClass:'text-right',
        headerClass: "ag-right-aligned-header",
        headerValueGetter:(params)=>this.translate.instant("BankingModule.BankAccounts.Spent")
      },
      {
        headerName: "Received", sortable: true,
        field: "received",
        width: 150,
        cellClass:'text-right',
        headerClass: "ag-right-aligned-header",
        cellRenderer: (params) => {
          if (params.value != null) {
            return '<a class="t-link edit">' + params.value + '</a>';
          }
        },
        headerValueGetter:(params)=>this.translate.instant("BankingModule.BankAccounts.Received")
      }
    ];
  }


  ViewStatement(stlid,CAAccountID,pageid?) {
    if(pageid){
      this.PageID = pageid;
    }
    this.CAAccountID=CAAccountID;
    this.createStmtColumnDefs();
    this.getStatementTransaction(stlid);
    this.showreconciliationmodal.show();
  }

  async getStatementTransaction(stlid): Promise<void> {
    this.loading = true;
    if (stlid != undefined && stlid != null && stlid > 0) {
      this.bankService.getStatementTransaction(stlid)
        .then((data: any) => {
          this.rowDataAdj = data['reconcileAdjustmentTable'];
          this.rowDataStmt = [];
          let rowFeild = {
            stmtDescription: data['stmtDescription'],
            type: data['type'],
            spent: data['spent'],
            received: data['received']
          }
          this.rowDataStmt.push(rowFeild);
          this.loading = false;
          let canDeleteAll = true;
          this.rowDataAdj.forEach(row => {
          if (row.mappingTransactionTypeID === 9) {
            if (!this.uarTM?.canDelete) canDeleteAll = false; 
          }
          else if (row.mappingTransactionTypeID === 10 || row.mappingTransactionTypeID === 23 || row.mappingTransactionTypeID === 33) {
            if (!this.uarSM?.canDelete) canDeleteAll = false;
          } 
          else if (row.mappingTransactionTypeID === 11 || row.mappingTransactionTypeID === 24 || row.mappingTransactionTypeID === 32) {
            if (!this.uarRM?.canDelete) canDeleteAll = false;
          }
    });
    this.showRmRdOps = canDeleteAll;

        }, error => {
          this.loading = false;
          console.log(error);
        });
    }
  }

  async deleteAccountTrans() {
    let dataAcc = this.rowDataAdj;
    let accountTransactions: any = [];
    this.rowDataAdj.forEach((row: any, index) => {
      accountTransactions.push({
        AccountTranTypeID: Number(row.mappingTransactionTypeID),
        AccountTranID: Number(row.mappingTransactionID)
      })
    });

    var con = await Swal.fire({
      title: this.translate.instant('Common.Are you sure want to remove?'),
      text: this.translate.instant('BankingModule.BankAccounts.You will delete all payments reconciliation items'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('BankingModule.BankAccounts.Yes, delete it!'),
      cancelButtonText: this.translate.instant('BankingModule.BankAccounts.No, keep it')
    });
    if (con.dismiss === Swal.DismissReason.cancel) {
      this.statusMsgId = this.alertMessage.warningNotifier(this.translate.instant("BankingModule.BankAccounts.Cancelled"), this.statusMsgId);
      return;
    }
    else if (con.isConfirmed) {
      for (let i = 0; i < accountTransactions.length; i++) {
        if (await this.isExistsAllocateCredit(accountTransactions[i].AccountTranTypeID, accountTransactions[i].AccountTranID) == true) {
          accountTransactions.splice(i, 1);
        }
      }
      
      if (accountTransactions.length > 0) {
        this.bankService.DeleteAccountTransactions(accountTransactions).then((data) => {
          this.showreconciliationmodal.hide();
          if (data != null && data != "") {
            this.statusMsgId = this.alertMessage.errorNotifier(data.toString(), this.statusMsgId);
          } else {
            this.statusMsgId = this.alertMessage.successNotifier(accountTransactions.length + " " + this.translate.instant("BankingModule.BankAccounts.account transactions were removed successfully."), this.statusMsgId);
          }
        }, error => {
          this.showreconciliationmodal.hide();
          console.log(error);
          this.statusMsgId = this.alertMessage.errorNotifier(this.translate.instant("BankingModule.BankAccounts.Error while deleting the Account Transactions"), this.statusMsgId);
        });        
        this.router.navigate(['bankaccounts/accounttransactions'], { queryParams: { id: btoa(this.CAAccountID)  } })
        this.sentToParent.emit(String("Deleted"));
      }
      else {
        this.showreconciliationmodal.hide();
        this.statusMsgId = this.alertMessage.successNotifier(accountTransactions.length + " " + this.translate.instant("BankingModule.BankAccounts.account transactions were removed.Because the payment has cash refund(s) and/or allocations made on it"), this.statusMsgId);
      }
    }
  }
  async isExistsAllocateCredit(mappingTransactionTypeID: number, tranid: number): Promise<boolean> {
    var isExists: boolean = true;
    await this.bankService.isExistsAllocateCredit(mappingTransactionTypeID, tranid).then((data) => {
      isExists = data["isExists"];
    }, error => {
      //this.errorNotifierId = this.alertMessage.errorNotifier("Error while deleting the Account Transactions", this.errorNotifierId);
    });
    return isExists;
  }

}
