import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { alertSucessBody } from '../../utilities/_models/alertMessage'
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../_services/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

export class AlertMessage {

  constructor(private router: Router, private toastr: ToastrService, private authenticationService: AuthenticationService,private translate:TranslateService) {

  }

  private SucessBody: alertSucessBody;
  simpleAlert() {
    Swal.fire('Hello Angular');
  }

  alertWithSuccess() {
    Swal.fire('Thank you...', 'You submitted successfully!', 'success')
  }

  clearAlertbox() {
    Swal.close();
  }

  alertWithSuccessRedirect(BodyContent: any, ID?: any) {
    this.SucessBody = BodyContent;
  
    Swal.fire({
      title: this.SucessBody.title ? this.SucessBody.title : 'Thank you...',
      text: this.SucessBody.message ? this.SucessBody.message : 'You submitted successfully!',
      icon: 'success',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        if (this.SucessBody.callback != "" && this.SucessBody.callback != undefined && this.SucessBody.callback != null) {
          if (this.SucessBody.action == "approved") {
            let qs = this.SucessBody.callback.split(",");
            if (qs.length > 0) {
              this.router.navigate([qs[0]], { queryParams: { returnback: btoa(this.SucessBody.callback) } });
            }
          } 
          else if (this.SucessBody.action == "approveAddAnother") {
            this.router.navigate([this.SucessBody.url], { queryParams: { callback: btoa(this.SucessBody.callback) } })
              .then(() => {
                window.location.reload();
              });
          }
        }
        else if(this.SucessBody.page && this.SucessBody.page !== undefined && this.SucessBody.page !== ''){
          if(this.SucessBody.page == '45' || this.SucessBody.page == '46' || this.SucessBody.page == '47'){
            if(this.SucessBody.account!=null && this.SucessBody.account!=undefined && this.SucessBody.account!='' && this.SucessBody.account!=0){
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
                  this.router.navigate([this.SucessBody.url], { queryParams: { pageid: btoa(this.SucessBody.page), account: btoa(this.SucessBody.account) }}));
                
            }else{
              this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
                this.router.navigate([this.SucessBody.url], { queryParams: { pageid: btoa(this.SucessBody.page) }}));
            }
          }
        }
        else if (!!ID && ID !== 2) {
          this.router.navigate([this.SucessBody.url], { queryParams: { id: btoa(ID) } });
        } else if (ID == 0) {
          if(this.SucessBody.account!=null && this.SucessBody.account!=undefined && this.SucessBody.account!='' && this.SucessBody.account!=0){
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
              this.router.navigate([this.SucessBody.url], { queryParams: { account: btoa(this.SucessBody.account) }}));
            
          }else{
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
            this.router.navigate([this.SucessBody.url], { queryParams: { id: btoa(ID) } }));
          }
        }
        else if (ID === 2) {
          if(this.SucessBody.url == 'purchase/bills/newbill'){
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
            this.router.navigate([this.SucessBody.url], { queryParams: { id: btoa('0') } }));
          }
          else{
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
            this.router.navigate([this.SucessBody.url], { queryParams: { id: btoa(ID) } }));
          }
        }
        else {
          if (!!this.SucessBody.url) {
            this.router.navigate([this.SucessBody.url]);
          }
        }
      }
    });
  }

  CreditnotealertWithSuccessRedirect(BodyContent, ID?: any) {
    this.SucessBody = BodyContent;
    Swal.fire({
      title: this.SucessBody.title ? this.SucessBody.title : 'Thank you...',
      text: this.SucessBody.message ? this.SucessBody.message : 'You submitted successfully!',
      icon: 'success',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        if (this.SucessBody.callback != "" && this.SucessBody.callback != undefined && this.SucessBody.callback != null) {
          if (this.SucessBody.action == "approved") {
            let qs = this.SucessBody.callback.split(",");
            if (qs.length > 0) {
              this.router.navigate([qs[0]], { queryParams: { returnback: btoa(this.SucessBody.callback) } });
            }
          }
          else if (this.SucessBody.action == "approveAddAnother") {
            this.router.navigate([this.SucessBody.url], { queryParams: { callback: btoa(this.SucessBody.callback) } })
              .then(() => {
                window.location.reload();
              });
          }
          if (this.SucessBody.action == "approveAddAnotherWithBill" && ID === 4) {

            this.router.navigate([this.SucessBody.url], { queryParams: { billid: btoa(this.SucessBody.callback) } })
              .then(() => {
                window.location.reload();
              });
          }
        }
        else if (!!ID && ID !== 4) {
          this.router.navigate([this.SucessBody.url], { queryParams: { id: btoa(ID) } });
        } else if (ID == 0) {
          this.router.navigate([this.SucessBody.url], { queryParams: { id: btoa(ID) } });
        }
        else if (ID === 4) {
          this.router.navigate(['purchase/creditnotes/newcreditnote'])
            .then(() => {
              window.location.reload();
            });
        }
        else {
          if (!!this.SucessBody.url) {
            this.router.navigate([this.SucessBody.url]);
          }
        }
      }
    })
  }

  SalesCreditnotealertWithSuccessRedirect(BodyContent, ID?: any) {
    this.SucessBody = BodyContent;

    Swal.fire({
      title: this.SucessBody.title ? this.SucessBody.title : 'Thank you...',
      text: this.SucessBody.message ? this.SucessBody.message : 'You submitted successfully!',
      icon: 'success',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        if (this.SucessBody.callback != "" && this.SucessBody.callback != undefined && this.SucessBody.callback != null) {
          if (this.SucessBody.action == "approved") {
            let qs = this.SucessBody.callback.split(",");
            if (qs.length > 0) {
              this.router.navigate([qs[0]], { queryParams: { returnback: btoa(this.SucessBody.callback) } });
            }
          }
          else if (this.SucessBody.action == "approveAddAnother") {
            this.router.navigate([this.SucessBody.url], { queryParams: { callback: btoa(this.SucessBody.callback) } })
              .then(() => {
                window.location.reload();
              });
          }
          if (this.SucessBody.action == "approveAddAnotherWithInv" && ID === 8) {
            this.router.navigate([this.SucessBody.url], { queryParams: { invoiceid: btoa(this.SucessBody.callback) } })
              .then(() => {
                window.location.reload();
              });
          }
        }
        else if (!!ID && ID !== 8) {
          this.router.navigate([this.SucessBody.url], { queryParams: { id: btoa(ID) } });
        } else if (ID == 0) {
          this.router.navigate([this.SucessBody.url], { queryParams: { id: btoa(ID) } });
        }
        else if (ID === 8) {

          this.router.navigate(['sales/creditnotes/newcreditnote'])
            .then(() => {
              window.location.reload();
            });
        }
        else {
          if (!!this.SucessBody.url) {
            this.router.navigate([this.SucessBody.url]);
          }
        }
      }
    })
  }

  QuotesalertWithSuccessRedirect(BodyContent, ID?: any, issend?: any) {
    this.SucessBody = BodyContent;
    Swal.fire({
      title: this.SucessBody.title ? this.SucessBody.title : 'Thank you...',
      text: this.SucessBody.message ? this.SucessBody.message : 'You submitted successfully!',
      icon: 'success',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        if(this.SucessBody.action == "approveaddanother" || this.SucessBody.action == "saveanother"){
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
          this.router.navigate([this.SucessBody.url], { queryParams: { id: btoa('0') } }));
        }
        else{
          var ParamsId = '0';
          if(this.SucessBody.url.indexOf('/newquotation') > -1) {
            ParamsId = '0';
          }
          else{
            if(issend === -2){
              ParamsId = ID + "," + '5' + "," + issend;
            }
            else{
              ParamsId = ID + "," + '5'
            }
          }
          this.router.navigate([this.SucessBody.url], { queryParams: { id: btoa(ParamsId) } });
        }
      }
    })
  }

  InvoicealertWithSuccessRedirect(BodyContent, ID?: any, contactid?: any, isprint?: any) {
    this.SucessBody = BodyContent;
    Swal.fire({
      title: this.SucessBody.title ? this.SucessBody.title : 'Thank you...',
      text: this.SucessBody.message ? this.SucessBody.message : 'You submitted successfully!',
      icon: 'success',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.value) {
        if (this.SucessBody.callback != "" && this.SucessBody.callback != undefined && this.SucessBody.callback != null) {
          if (this.SucessBody.action == "Approve") {
            let qs = this.SucessBody.callback.split(",");
            if (qs.length > 0) {
              this.router.navigate([qs[0]], { queryParams: { returnback: btoa(this.SucessBody.callback) } });
            }
          }
          else if (this.SucessBody.action == "approveaddanother") {
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
            this.router.navigate([this.SucessBody.url], { queryParams: { id: btoa('0'), callback: btoa(this.SucessBody.callback) } }));
          }
        }
        else {
          if(this.SucessBody.action == "approveaddanother" || this.SucessBody.action == "saveanother"){
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
            this.router.navigate([this.SucessBody.url], { queryParams: { id: btoa('0') } }));
          }
          else{
            var ParamsId = '0';
            if(this.SucessBody.url.indexOf('/newinvoice') > -1) {
              ParamsId = '0';
            }
            else{
              if(isprint){
                ParamsId = ID + "," + '6' + "," + contactid + "," + isprint;
              }
              else{
                ParamsId = ID + "," + '6' + "," + contactid+ ",-1";
              }
            }
            this.router.navigate([this.SucessBody.url], { queryParams: { id: btoa(ParamsId) } });
          }
        }
      }
    })
  }

  ReceivesalertWithSuccessRedirect(BodyContent, ID?: any) {
    this.SucessBody = BodyContent;
    Swal.fire({
      title: this.SucessBody.title ? this.SucessBody.title : 'Thank you...',
      text: this.SucessBody.message ? this.SucessBody.message : 'You submitted successfully!',
      icon: 'success',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        if (!!ID) {
          var ParamsId = ID;
          this.router.navigate([this.SucessBody.url], { queryParams: { id: btoa(ParamsId) } });
        }
        else {
          if (!!this.SucessBody.url) {
            this.router.navigate([this.SucessBody.url]);
          }
        }
      }
    })
  }

  /**
  * Send a custom alertWithCustomizeSuccess.
  * @param object alertSucessBody - example : alertSucessBody.title, alertSucessBody.message
  */
  alertWithCustomizeSuccess(SucessBody) {
    this.SucessBody = SucessBody;
    Swal.fire
      (
        this.SucessBody.title ? this.SucessBody.title : 'Thank you...',
        this.SucessBody.message ? this.SucessBody.message : 'You submitted successfully!',
        'success'
      )
  }

  alertWithError() {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
      footer: '<a href>Why do I have this issue?</a>'
    })
  }

  /**
 * Send a custom alertWithCustomizeError.
 * @param object alertSucessBody - example : alertSucessBody.title, alertSucessBody.message, alertSucessBody.footer
 */
  alertWithCustomizeError(SucessBody) {
    this.SucessBody = SucessBody;
    Swal.fire
      ({
        icon: 'error',
        title: this.SucessBody.title ? this.SucessBody.title : this.translate.instant('ToolsModule.Oops...'),
        text: this.SucessBody.message ? this.SucessBody.message : 'Something went wrong!',
        footer: this.SucessBody.footer ? this.SucessBody.footer : '', //'<a href>Why do I have this issue?</a>'
        confirmButtonText: this.translate.instant('Common.Ok')
      })
  }

  /**
* Send a custom alertWithCustomizeWarning.
* @param object alertSucessBody - example : alertSucessBody.title, alertSucessBody.message, alertSucessBody.footer
*/
  alertWithCustomizeWarning(SucessBody) {
    this.SucessBody = SucessBody;
    Swal.fire
      ({
        icon: 'warning',
        title: this.SucessBody.title ? this.SucessBody.title : 'Oops...',
        text: this.SucessBody.message ? this.SucessBody.message : 'Something went wrong!',
        footer: this.SucessBody.footer ? this.SucessBody.footer : '<a href>Why do I have this issue?</a>'
      })
  }

  alertWithCustomizeWarningHTML(SucessBody) {
    this.SucessBody = SucessBody;
    Swal.fire
      ({
        icon: 'warning',
        title: this.SucessBody.title ? this.SucessBody.title : 'Oops...',
        html: this.SucessBody.message ? this.SucessBody.message : 'Something went wrong!',
        footer: this.SucessBody.footer ? this.SucessBody.footer : '<a href>Why do I have this issue?</a>'
      })
  }

  topEndRightAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Your work has been saved',
      showConfirmButton: false,
      timer: 1500
    })
  }

  alertWithConfirmBox() {
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Deleted!',
          'Your imaginary file has been deleted.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  }

  /**
* Send a custom alertWithCustomizeConfirmBox.
* @param object alertSucessBody - example : alertSucessBody.title, alertSucessBody.message, alertSucessBody.confirmButtonText, alertSucessBody.cancelButtonText
* alertSucessBody.returnTitle, alertSucessBody.returnMessage
* alertSucessBody.dismisTitle, alertSucessBody.dismisMessage
*/
  alertWithCustomizeConfirmBox(SucessBody) {
    this.SucessBody = SucessBody;
    Swal.fire({
      title: this.SucessBody.title ? this.SucessBody.title : 'Are you sure want to remove?',
      text: this.SucessBody.message ? this.SucessBody.message : 'You will not be able to recover this file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.SucessBody.confirmButtonText ? this.SucessBody.confirmButtonText : 'Yes, delete it!',
      cancelButtonText: this.SucessBody.cancelButtonText ? this.SucessBody.cancelButtonText : 'No, keep it'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          this.SucessBody.returnTitle ? this.SucessBody.returnTitle : 'Deleted!',
          this.SucessBody.returnMessage ? this.SucessBody.returnMessage : 'Your imaginary file has been deleted.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.SucessBody.dismisTitle ? this.SucessBody.dismisTitle : 'Cancelled',
          this.SucessBody.dismisMessage ? this.SucessBody.dismisMessage : 'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  }




  //please don't create new notifier alert method

  successNotifier(message: string, id: number) {
    this.toastr.remove(id);
    let title=this.translate.instant("Success!");
    const activeToast = this.toastr.success(
      message,
      title,
      {
        enableHtml: true,
        timeOut: 5000,
        tapToDismiss: false,
        closeButton: true,
      },
    );
    return activeToast.toastId;
  }

  infoNotifier(message: string, id: number) {
    this.toastr.remove(id);
    const activeToast = this.toastr.info(
      message,
      'Information!',
      {
        enableHtml: true,
        timeOut: 5000,
        tapToDismiss: false,
        closeButton: true,
      }
    );
    return activeToast.toastId;
  }

  errorNotifier(message: string, id: number) {
    this.toastr.remove(id);
    let title=this.translate.instant("SettingsModule.Error") + "!";
    const activeToast = this.toastr.error(
      message,
      title,
      {
        enableHtml: true,
        timeOut: 10000,
        tapToDismiss: false,
        closeButton: true,
      }
    );
    return activeToast.toastId;
  }

  CommonerrorNotifier(message: string, id: number) {
    this.toastr.remove(id);
    let title=this.translate.instant("Error") + "!";
    const activeToast = this.toastr.error(
      message,
      title,
      {
        enableHtml: true,
        timeOut: 10000,
        tapToDismiss: false,
        closeButton: true,
      }
    );
    return activeToast.toastId;
  }

  errorNotifierList(lstErrors: any[], id: number) {
    let message = "";
    if (lstErrors.length > 0) {
      for (var i = 0; i < lstErrors.length; i++) {
        message = message + '<li>' + lstErrors[i].message + '</li>';
      }
      message = "<ul>" + message + "</ul>";

      this.toastr.remove(id);
      const activeToast = this.toastr.error(
        message,
        'Error!',
        {
          enableHtml: true,
          timeOut: 8000,
          tapToDismiss: false,
          closeButton: true,
        }
      );
      return activeToast.toastId;
    }
    else {
      return 0;
    }
  }

  errorTitleNotifier(message: string, title: string, id: number) {
    this.toastr.remove(id);
    const activeToast = this.toastr.error(
      message,
      title,
      {
        enableHtml: true,
        timeOut: 8000,
        tapToDismiss: false,
        closeButton: true,
      }
    );
    return activeToast.toastId;
  }


  warningNotifier(message: string, id: number) {
    let title=this.translate.instant("Warning!")
    this.toastr.remove(id);
    const activeToast = this.toastr.warning(
      message,
      title,
      {
        enableHtml: true,
        timeOut: 5000,
        tapToDismiss: false,
        closeButton: true,
      }
    );
    return activeToast.toastId;
  }


  errorNotifierManualClose(lstErrors: any, id: number) {
    let message = "";
    if (lstErrors.length > 0) {
      for (var i = 0; i < lstErrors.length; i++) {
        message = message + '<li>' + lstErrors[i].message + '</li>';
      }
      message = "<ul>" + message + "</ul>";

      this.toastr.remove(id);
      const activeToast = this.toastr.error(
        message,
        'Error!',
        {
          enableHtml: true,
          disableTimeOut: true,
          tapToDismiss: false,
          closeButton: true,
        }
      );
      return activeToast.toastId;
    }
    else {
      return 0;
    }
  }

  successRoutingNotifier(message: string, url: string, queryParams: any) {
    const activeToast = this.toastr.success(
      message,
      'Success!',
      {
        enableHtml: true,
        timeOut: 5000,
        tapToDismiss: false,
        closeButton: true,
      },
    );
    setTimeout(() => {
      if (queryParams == '' || queryParams == undefined) {
        this.router.navigate([url]);
      }
      else {
        this.router.navigate([url], { queryParams });
      }
    }, 3000);
  }

  errorRoutingNotifier(message: string, url: string, queryParams: any) {
    const activeToast = this.toastr.error(
      message,
      'Error!',
      {
        enableHtml: true,
        timeOut: 5000,
        tapToDismiss: false,
        closeButton: true,
      },
    );
    setTimeout(() => {
      if (queryParams == '' || queryParams == undefined) {
        this.router.navigate([url]);
      }
      else {
        this.router.navigate([url], { queryParams });
      }
    }, 3000);
  }

  clearNotifier() {
    this.toastr.clear();
  }

  errorSummaryNotifierWithAutoClose(lstErrors: any, id: number) {
    let message = "";
    if (lstErrors.length > 0) {
      for (var i = 0; i < lstErrors.length; i++) {
        message = message + '<li>' + lstErrors[i].message + '</li>';
      }
      message = "<ul>" + message + "</ul>";

      this.toastr.remove(id);
      const activeToast = this.toastr.error(
        message,
        'Error!',
        {
          enableHtml: true,
          tapToDismiss: true,
          closeButton: true,
          timeOut: 5000,
        } 
      );
      return activeToast.toastId;
    }
    else {
      return 0;
    }
  }

  logoutAlertWithConfirmBox(BodyContent: any) {
    this.SucessBody = BodyContent;

    Swal.fire({
      title: this.SucessBody.title ? this.SucessBody.title : 'Logout',
      text: this.SucessBody.message ? this.SucessBody.message : 'Go to logout!',
      icon: 'warning',
      showCancelButton: false,
      allowOutsideClick: false,
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.value) {
        this.clearNotifier();
        this.authenticationService.logout();
      }
    })
  }

  errorSummaryNotifierforIRAS(lstErrors: any, id: number) {
    let message = "";
    if (lstErrors.length > 0) {
      for (var i = 0; i < lstErrors.length; i++) {
        message = message + '<li>' + lstErrors[i].field + ' { ' + lstErrors[i].message + ' } ' + '</li>';
      }
      message = "<ul>" + message + "</ul>";

      this.toastr.remove(id);
      const activeToast = this.toastr.error(
        message,
        'Error!',
        {
          enableHtml: true,
          disableTimeOut: true,
          tapToDismiss: false,
          closeButton: true,
        }
      );
      return activeToast.toastId;
    }
    else {
      return 0;
    }
  }

  SalesOrderSuccessRedirect(BodyContent) {
    this.SucessBody = BodyContent;

    Swal.fire({
      title: this.SucessBody.title ? this.SucessBody.title : 'Thank you...',
      text: this.SucessBody.message ? this.SucessBody.message : 'Saved successfully!',
      icon: 'success',
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        if (this.SucessBody.callback != "" && this.SucessBody.callback != undefined && this.SucessBody.callback != null) {
          if (this.SucessBody.action == "approve") {
            let qs = this.SucessBody.callback.split(",");
            if (qs.length > 0) {
              this.router.navigate([qs[0]], { queryParams: { returnback: btoa(this.SucessBody.callback) } });
            }
          }
          else if (this.SucessBody.action == "approveaddanother") {
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
            this.router.navigate([this.SucessBody.url], { queryParams: { id: btoa('0'), callback: btoa(this.SucessBody.callback) } }));
          }
        }
        else {
          if(this.SucessBody.params){
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
            this.router.navigate([this.SucessBody.url], { queryParams: { id:this.SucessBody.params } }));
          }
          else{
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
            this.router.navigate([this.SucessBody.url]));
          }
        }
        
      }
    })
  }
  closeNotifier(id){
    this.toastr.remove(id);
  }
}
