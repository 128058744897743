import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { email } from '@rxweb/reactive-form-validators';
import { emailValidatorExtension } from '@rxweb/reactive-form-validators/validators-extension';
import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';
import { currencyFormatter } from '../../../utilities/_common/commonfunctions';
import { CustomTxtValidator, CustomValidator, ValidEmail, ValidPhone } from '../../../utilities/_directives/customvalidator.directive';
import { LocalCacheServices } from '../../../utilities/_services/acclocalcache.service';
import { HttpServices } from '../../../utilities/_services/http.service';
import { NumericdiscountEditorComponent } from '../../shared/gridComponent/numericdiscount-editor.component ';

@Component({
  selector: 'app-payment',
  templateUrl: './subscriptionpayment.component.html',
  styleUrls: ['./subscriptionpayment.component.scss']
})
export class SubscriptionPaymentComponent implements OnInit {
  @ViewChild('stepper') public stepper: MatStepper;
  CountryID: number = 0;
  OrgID: number = 0;
  pageFrom: number = 0;
  CustomerCode: number = 0;
  PlanType: number = 0;

  valid0 = new FormControl('')
  valid1 = new FormControl('')
 
  currentStep: number = 0;
  lstProducts = [];
  lstPlans: any = [];
  lstCountries: any = [];
  lstMonth: any = [];
  lstYear: any = [];
  billingAccountForm: FormGroup | any; 
  objSummary = {
    OrgID: 0,
    CustomerCode: 0,
    ProCountryID: 0,

    ProductID: 0,
    ProductCode: "",
    ProductName: "",
     ProductToken: "",
    TaxRate: 0.00,
    CurrencyCode: "",

    SubscriptionPriceID: 0,
    SubscriptionPrice: 0.00,
    RecurrenceId: 0,
    RecurrencePeriod: "",

    DiscountPercentage:"",
    DiscountCode: "",
    Discount: 0.00,

    NetAmount: 0.00,
    TaxAmount: 0.00,
    PaidAmount: 0.00,

    BillingAccountName: '',
    Email: '',
    Phone: '',
    CountryID: null,
    Country: '',
    Address: '',
    City: '',
    State: '',
    ZipCode: '',
    pageOption:0,

    key:'',
    sessionid:''
  };

  submitted2 = false;
  loading = false;
  rdata: any;
  isWithoutLogin:boolean = false;
  key: any;
  sessionId: any;
  status: any;
  from: any;
  TransactionDatakey: any;

  constructor(private formBuilder: FormBuilder, private http: HttpServices, private route: ActivatedRoute, private router: Router,
    public local: LocalCacheServices) {
      
    this.route.queryParams.subscribe(params => {
      if (params['subParams'] != undefined && params['subParams'] != "") {
        var subParam = JSON.parse(atob(params['subParams']));
        this.CountryID = subParam.countryID;
        this.CustomerCode = subParam.customercode;
        this.OrgID = subParam.orgID;
        this.pageFrom = subParam.pageOption;
        this.isWithoutLogin = (subParam.isWithoutLogin)?subParam.isWithoutLogin:false;
        this.PlanType = (subParam.planType)?subParam.planType:1;
      }
      this.route.queryParams.subscribe(params => {
        this.from = params.from;
        this.key = params.key;
        this.status = params.status;

      });
    });


    this.setState(this.valid0, true);
    this.setState(this.valid1, true);
    this.loadCountries();    
  }
  setState(control: FormControl, state: boolean) {
    if (state) {
      control.setErrors({ "required": true })
    } else {
      control.reset()
    }
  }
 
  async ngOnInit(){
    this.getProductList();
    this.billingAccountForm = this.formBuilder.group({
      BillingAccountName: ['', CustomTxtValidator.required],
      Email: ['', [Validators.required, Validators.pattern(ValidEmail.pattern)]],
      Phone: ['', [Validators.required, Validators.pattern(ValidPhone.pattern)]],
      CountryID: [null, CustomValidator.required],
      Address: ['', CustomTxtValidator.required],
      City: ['', CustomTxtValidator.required],
      State: ['', CustomTxtValidator.required],
      ZipCode: ['', CustomTxtValidator.required]
    });

    if(this.from =="stripe")
    {
      this.objSummary.OrgID = this.OrgID;
      this.objSummary.CustomerCode = this.CustomerCode;
      this.objSummary.key=this.key;
      this.objSummary.sessionid=this.sessionId;
      this.objSummary.State=this.status;
      let payload = { paymentJson: JSON.stringify(this.objSummary) };
      this.http.postservice(environment.subscriptionApiUrl + '/StripePayment/StripeCallback', payload).subscribe({
        next: (data: any) => {
        this.rdata = data;
         
          }
        });
    }

  }

  get ba() { return this.billingAccountForm.controls; }

  async loadCountries() {    
    let data = await this.http.getserviceawait(environment.subscriptionApiUrl + '/StripePayment/GetCountries');
    this.lstCountries = data;
  }

  getProductList() {
    this.http.getservice(environment.subscriptionApiUrl + '/StripePayment/GetProductList?CountryID=' + this.CountryID).subscribe({
      next: (rdata: any) => {
        let data = JSON.parse(rdata.responseData);
        this.lstProducts = data;
        this.selectProduct(this.lstProducts[0].ProductID);
      }
    });
  }

  onStepChange(evt: any) {
    this.currentStep = evt.selectedIndex;
  }
  previous(step) {
    this.stepper.previous();
  }

  next(step: number) {
    if (step == 0) {
      if (this.objSummary.RecurrenceId) {
        this.setState(this.valid0, false);
      }
      else {
        this.setState(this.valid0, true);
      }
    }
   
    this.stepper.next();
  }

  formcurrencyFormatter(value) {
    return currencyFormatter(value);
  }

  selectProduct(ProductID: number) {
    this.objSummary.ProductID = ProductID;
    let p = this.lstProducts.filter(x => x.ProductID == this.objSummary.ProductID);
    this.objSummary.ProductCode = p[0].ProductCode;
    this.objSummary.ProductName = p[0].ProductName;

    this.objSummary.ProductToken = p[0].ProductToken;
    this.objSummary.TaxRate = p[0].TaxRate ? p[0].TaxRate : 0;
    this.objSummary.CurrencyCode = p[0].CurrencyCode;
    this.lstPlans = p[0].lstPlans;

    if(this.PlanType > 0){
      this.selectPlan(this.PlanType);
    }
    else if (!this.objSummary.RecurrenceId) {
      this.selectPlan(this.lstPlans[0].RecurrenceId);
    }
  };

  selectPlan(RecurrenceId: number) {    
    this.objSummary.RecurrenceId = RecurrenceId ? RecurrenceId : 0;
    let p = this.lstPlans.filter(x => x.RecurrenceId == this.objSummary.RecurrenceId);

    if(p.length<=0)
    {
      p = this.lstPlans.filter(x => x.RecurrenceId == 2); //yearly
    }

    this.objSummary.RecurrencePeriod = p[0].RecurrencePeriod;
    this.objSummary.SubscriptionPriceID = p[0].SubscriptionPriceID;
    this.objSummary.SubscriptionPrice = currencyFormatter(p[0].SubscriptionPrice ? p[0].SubscriptionPrice : 0);
    
    this.objSummary.DiscountPercentage = p[0].DiscountPercentage ? p[0].DiscountPercentage : 0;
    this.objSummary.Discount = currencyFormatter(p[0].DiscountAmount ? p[0].DiscountAmount : 0);
    this.objSummary.NetAmount = currencyFormatter(p[0].NetAmount ? p[0].NetAmount : 0);
    this.objSummary.TaxAmount = currencyFormatter(this.objSummary.TaxRate > 0 ? ((p[0].NetAmount) / 100) * this.objSummary.TaxRate : 0);
    let t = (this.objSummary.TaxRate > 0 ? ((p[0].NetAmount) / 100) * this.objSummary.TaxRate : 0);
    this.objSummary.PaidAmount = currencyFormatter(p[0].NetAmount + t);
  };

  makePayment() {
    this.submitted2 = true;
    if (this.billingAccountForm.invalid) {
      this.setState(this.valid1, true);
      return;
    }
    this.setState(this.valid1, false);
    this.submitted2 = false;


    this.objSummary.BillingAccountName = this.billingAccountForm.value.BillingAccountName
    this.objSummary.Email = this.billingAccountForm.value.Email
    this.objSummary.Phone = this.billingAccountForm.value.Phone
    if (this.billingAccountForm.value.CountryID) {
      let c = this.lstCountries.filter(x => x.countryID == this.billingAccountForm.value.CountryID)
      this.objSummary.Country = c[0].countryName;
    }
    this.objSummary.CountryID = this.billingAccountForm.value.CountryID;
    this.objSummary.Address = this.billingAccountForm.value.Address;
    this.objSummary.City = this.billingAccountForm.value.City;
    this.objSummary.State = this.billingAccountForm.value.State;
    this.objSummary.ZipCode = this.billingAccountForm.value.ZipCode;
    
    this.objSummary.OrgID = this.OrgID;
    this.objSummary.CustomerCode = this.CustomerCode;
    this.objSummary.ProCountryID = this.CountryID;
    this.objSummary.pageOption= this.pageFrom;
 
    this.loading = true;
    let payload = { paymentJson: JSON.stringify(this.objSummary) };
    this.http.postservice(environment.subscriptionApiUrl + '/StripePayment/CreateCheckoutSession', payload).subscribe({
      next: (data) => {
        if (data["isSuccess"] == false) {
         
        } else {
          window.location.href = data['url'];
        }
      },
      error: error => {
        console.log(error);
      }
    }); 
  }

  createInvoice2(InvoiceInfo: any) {
    return new Promise(resolve => {
      this.http.awaitPostservice(environment.subscriptionApiUrl + '/Subscription/createInvoice', InvoiceInfo)
        .then((dt: any) => {
          resolve(dt);
        });
    });
  }

  createInvoicePayment2(invoicePaymentInfo: any) {
    this.http.postservice(environment.subscriptionApiUrl + '/Subscription/createInvoicePayment', invoicePaymentInfo)
      .subscribe((invoicePaymentResponse: any) => {

      });
  }

  createInvoicePayment(invoicePaymentInfo: any) {
    this.http.awaitPostservice(environment.subscriptionApiUrl + '/Subscription/createInvoicePayment', invoicePaymentInfo)
      .then((invoicePaymentResponse: any) => {

      });
  }

  RedirectToHome() {
    let timerInterval
    Swal.fire({
      title: 'Thanks for choosing Info-Accounting!',
      html: "Login information has been shared to registered email ID. Now you're redirecting to home page.",
      timer: 10000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      this.router.navigate(['login']);
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('I was closed by the timer')
      }
    })
  }
}
