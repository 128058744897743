<meta name="robots" content="NOINDEX, NOFOLLOW">
<ng-container *ngIf="!Is2FA_IsTwoStepVerification && !Is2FA_IsAuthDevice">
<div *ngIf="!isDigisme" class="app-body c-app flex-row align-items-center login-bg">
  <main class="c-main">
    <div class="container-fluid" *ngIf="isForgot == false">
      <div class="row">
        <div class="col-md-9 mx-auto">
          <div class="card-group vh-75">
            <div class="card p-2 d-md-down-none border-0" style="background: #feffff;">
              <div class="card-body text-center">
                <div class="login-img-center">
                  <img class="mb-2 img-fluid" src="assets/brand/leftside_new.webp" alt="Info Tech" />
                  <div class="border-findus"></div>
                  <div class="find-over">
                    <div class="find-us">Find us at</div>
                  </div>
                  <div class="social-icons">
                    <a href="https://www.facebook.com/InfotechSystems" target="_blank">
                      <img src="assets/brand/iface.png" class="p-1" alt="Facebook"></a>
                    <a href="https://www.instagram.com/infotechsystems_/" target="_blank">
                      <img src="assets/brand/iinsta.png" class="p-1" alt="Instagram"></a>
                    <a href="https://www.info-tech.com.sg/" target="_blank">
                      <img src="assets/brand/isite.png" class="p-1" alt="Website"></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="card p-2 border-0 shadow-none" style="background: #feffff;">
              <div class="card-body">
                <div class="login-form-center">
                  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <img class="mb-4" src="assets/brand/Info-Tech-Logo.svg" height="50" alt="Info Tech" />
                    <h5 class="mb-4"><b>Welcome, Sign in to Accounting Software</b></h5>
                    <div class="input-group mb-4">
                      <input id="txtUserName" type="text" formControlName="username" class="form-control"
                        placeholder="Email Address / Mobile Number"
                        [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                      <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors?.required">Email Address or Mobile Number is required</div>
                        <div *ngIf="f.username.errors?.custom">Enter valid Email Address or Mobile Number</div>
                      </div>
                    </div>
                    <div class="input-group mb-5">
                      <input id="txtPassword" type="password" formControlName="password" class="form-control"
                        placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors?.required">Password is required</div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <button id="btnLogin" [disabled]="loading" class="btn btn-lg btn-block btn-success px-4"> <span
                            *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>LOGIN</button>
                      </div>
                    </div>
                    <div class="mt-4 mb-2 text-center">
                      <div id="btnForgot" class="t-link" (click)="forgotClick()">Forgot password?</div>
                    </div>
                    <!-- <p class="text-muted">Don’t have an account yet? <a href="#" class="t-link">Sign up now</a></p> -->
                    <div class="row">
                      <div class="col-12">
                        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <div *ngIf="maintanencenote" class="maintenance" [innerHTML]="maintanencenote"></div>
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" *ngIf="isForgot == true">
      <div class="row">
        <div class="col-md-9 mx-auto">
          <div class="card-group">
            <div class="card p-2 d-md-down-none border-0" style="background: #feffff;">
              <div class="card-body text-center">
                <div class="login-img-center">
                <img class="mb-3 img-fluid" src="assets/brand/leftside.png" style="height:250px;" alt="Info Tech" />
                <div class="social-icons">
                  <a href="https://www.facebook.com/InfotechSystems" target="_blank">
                    <img src="assets/brand/iface.png" class="p-1" alt="Facebook"></a>
                  <a href="https://www.instagram.com/infotechsystems_/" target="_blank">
                    <img src="assets/brand/iinsta.png" class="p-1" alt="Instagram"></a>
                  <a href="https://www.info-tech.com.sg/" target="_blank">
                    <img src="assets/brand/isite.png" class="p-1" alt="Website"></a>
                </div>
              </div>
              </div>
            </div>
            <div class="card p-2 border-0" style="background: #feffff;">
              <div class="card-body m-5">
                <form [formGroup]="ForgotForm" (ngSubmit)="onFPSubmit()">
                  <img class="mb-3" src="assets/brand/Info-Tech-Logo.svg" height="50" alt="Info Tech" />
                  <h5 class="mb-3"><b>Reset Your Password</b></h5>
                  <p class="text-muted">Enter your registered email address with us and a link will be sent to help you
                    with your account.</p>
                  <div class="input-group mb-3 mt-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-user"></i></span>
                    </div>
                    <input id="txtForgotUserName" type="text" formControlName="username" class="form-control"
                      placeholder="Enter Email Address or Mobile Number"
                      [ngClass]="{ 'is-invalid': submitted && fg.username.errors }" />
                    <div *ngIf="submitted && fg.username.errors" class="invalid-feedback">
                      <div *ngIf="fg.username.errors.required">Email Address or Mobile Number is required</div>
                      <div *ngIf="fg.username.errors?.custom">Enter valid Email Address or Mobile Number</div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div *ngIf="statusMsg" class="alert alert-danger mb-3">{{statusMsg}}</div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <button id="btnOnFPSubmit" [disabled]="loading" class="btn btn-block btn-primary px-4">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Send Reset Link
                      </button>
                    </div>
                  </div>
                  <div class="mt-3 mb-2">
                    <div id="bntBackToLogin" class="t-link" (click)="OnCancel();">Back to Login</div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
<div *ngIf="isDigisme"  class="app-body c-app flex-row align-items-center login-bg v-new">
  <main class="c-main p-0">
    <div class="" *ngIf="isForgot == false">
      <div class="">
        <div class="">
          <div class="auth-fluid">
            <div class="auth-fluid-content-box">
              <svg mlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                <defs>
                  <linearGradient id="grad" x1="10%" y1="10%" x2="100%" y2="100%">
                    <stop offset="0%" stop-color="#469dd4 "></stop>
                    <stop offset="100%" stop-color="#469dd4 "></stop>
                  </linearGradient>
                </defs>
                <path d="M0 10 C30 28 38 0 64 10 L64 0 L0 0 Z" fill="url(#grad)"></path>
              </svg>
              <div class="card-body text-center">
                <div class="login-img-center">
                  <h2 class="mb-4">DigiSME Accounting</h2>
                  <h3>Bookkeeping & Accounting On the go</h3>
                  <img src="assets/brand/login-digi.png" alt="Info Tech" style="height:45vh"
                    class="mb-2 img-fluid mx-auto d-block">
                  <p>Bills, Quotation, Invoice, Inventory, Tax Reports <br />Ready Auto Reconcile Bank Statements</p>
                  <div class="text-center d-flex justify-content-center"><a _ngcontent-stg-c323=""
                      href="https://www.digi-sme.com/" class="btn learn-btn mt-3">Find Out More</a></div>
                  <div class="social-icons d-none">
                    <a href="https://www.facebook.com/InfotechSystems" target="_blank">
                      <img src="assets/brand/iface.png" class="p-1" alt="Facebook"></a>
                    <a href="https://www.instagram.com/infotechsystems_/" target="_blank">
                      <img src="assets/brand/iinsta.png" class="p-1" alt="Instagram"></a>
                    <a href="https://www.info-tech.com.sg/" target="_blank">
                      <img src="assets/brand/isite.png" class="p-1" alt="Website"></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="auth-fluid-form-box">
              <div class="card-body">
                <div class="login-form-center">
                  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <img class="mb-3" src="assets/brand/digisme-logo.png" height="30" alt="Info Tech" />
                    <h5 class="mb-4"><b>Login to DigiSME Accounting</b></h5>
                    <div class=" mb-4">
                      <label>Email/Mobile No</label>
                      <input id="txtUserName" type="text" formControlName="username" class="form-control"
                        placeholder="Email Address / Mobile Number"
                        [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                      <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors?.required">Email Address or Mobile Number is required</div>
                        <div *ngIf="f.username.errors?.custom">Enter valid Email Address or Mobile Number</div>
                      </div>
                    </div>
                    <div class=" mb-3 position-relative">
                      <label>Password<span id="btnForgot" class="" (click)="forgotClick()">Forgot
                          password?</span></label>
                      <input id="txtPassword" [type]="pwdType" formControlName="password" class="form-control"
                        placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                      <i class="icon-eye" id="eye" (mousedown)="pwdType='text'" (mouseup)="pwdType='password'"></i>
                      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors?.required">Password is required</div>
                      </div>
                    </div>
                    <!-- <div class="checkbox mb-3 d-flex">
                      <input type="checkbox" id="remember" name="remember" value="remember">
                      <label for="remember"> Remember me</label><br>
                    </div> -->
                    <hr />
                    <div class="row">
                      <div class="col">
                        <button id="btnLogin" [disabled]="loading" class="btn btn-lg btn-block btn-success px-4 mt-2">
                          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Login</button>
                      </div>
                    </div>

                    <!-- <p class="text-muted">Don’t have an account yet? <a href="#" class="t-link">Sign up now</a></p> -->
                    <div class="row">
                      <div class="col-12">
                        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <div *ngIf="maintanencenote" class="maintenance" [innerHTML]="maintanencenote"></div>
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="" *ngIf="isForgot == true">
      <div class="">
        <div class="">
          <div class="auth-fluid">
            <div class="auth-fluid-content-box">
              <svg mlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                <defs>
                  <linearGradient id="grad" x1="10%" y1="10%" x2="100%" y2="100%">
                    <stop offset="0%" stop-color="#469dd4 "></stop>
                    <stop offset="100%" stop-color="#469dd4 "></stop>
                  </linearGradient>
                </defs>
                <path d="M0 10 C30 28 38 0 64 10 L64 0 L0 0 Z" fill="url(#grad)"></path>
              </svg>
              <div class="card-body text-center">
                <div class="login-img-center">
                  <h2 class="mb-4">DigiSME Accounting</h2>
                  <h3>Bookkepping & Accounting On the go</h3>
                  <img src="assets/brand/login-digi.png" alt="Info Tech" style="height:45vh"
                    class="mb-2 img-fluid mx-auto d-block">
                  <p>Bills, Quotation, Invoice, Inventory, Tax Reports <br />Ready Auto Reconcile Bank Statements</p>
                  <div class="text-center d-flex justify-content-center"><a _ngcontent-stg-c323=""
                      href="https://www.digi-sme.com/" class="btn learn-btn mt-3">Find Out More</a></div>
                  <div class="social-icons d-none">
                    <a href="https://www.facebook.com/InfotechSystems" target="_blank">
                      <img src="assets/brand/iface.png" class="p-1" alt="Facebook"></a>
                    <a href="https://www.instagram.com/infotechsystems_/" target="_blank">
                      <img src="assets/brand/iinsta.png" class="p-1" alt="Instagram"></a>
                    <a href="https://www.info-tech.com.sg/" target="_blank">
                      <img src="assets/brand/isite.png" class="p-1" alt="Website"></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="auth-fluid-form-box">
              <div class="card-body">
                <div class="">
                  <form [formGroup]="ForgotForm" (ngSubmit)="onFPSubmit()">
                    <img class="mb-3" src="assets/brand/digisme-logo.png" height="30" alt="Info Tech" />
                    <h5 class="mb-3"><b>Reset Your Password</b></h5>
                    <p class="text-muted">Enter your registered email address with us and a link will be sent to help
                      you
                      with your account.</p>
                    <div class="input-group mb-3 mt-4">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="icon-user"></i></span>
                      </div>
                      <input id="txtForgotUserName" type="text" formControlName="username" class="form-control"
                        placeholder="Enter Email Address or Mobile Number"
                        [ngClass]="{ 'is-invalid': submitted && fg.username.errors }" />
                      <div *ngIf="submitted && fg.username.errors" class="invalid-feedback">
                        <div *ngIf="fg.username.errors.required">Email Address or Mobile Number is required</div>
                        <div *ngIf="fg.username.errors?.custom">Enter valid Email Address or Mobile Number</div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div *ngIf="statusMsg" class="alert alert-danger mb-3">{{statusMsg}}</div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <button id="btnOnFPSubmit" [disabled]="loading" class="btn btn-block btn-primary px-4">
                          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                          Send Reset Link
                        </button>
                      </div>
                    </div>
                    <div class="mt-3 mb-2">
                      <div id="bntBackToLogin" class="t-link text-center" (click)="OnCancel();">Back to Login</div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
</ng-container>

<div bsModal #showsupportuser="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="showsupportuserLabel" aria-hidden="true" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content" id="supportusercontent">
      <div class="modal-header">
        <h4 class="modal-title">Support User - Login Selection</h4>
        <button id="btnOnSupportCancel-1" type="button" class="close" (click)="onSupportCancel()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row text-center mb-3">
          <div class="col-12 text-muted" [innerText]="popupText">
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            Please select which profile you would like to login
          </div>
          <div class="col-6">

            <ng-select id="ddUserTypeName" [items]="SupportUserOptions" [(ngModel)]="userType" bindValue="userType"
              bindLabel="userTypeName">
            </ng-select>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-12 text-right">
            <button id="btnOnSupportSubmit" [disabled]="loading" class="btn btn-success mr-4"
              (click)="onSupportSubmit()">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              Login
            </button>
            <button id="btnOnSupportCancel-2" (click)="onSupportCancel()"
              class="btn btn-danger pull-right">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="Is2FA_IsTwoStepVerification && !Is2FA_IsAuthDevice"
  class="authenticate-login app-body c-app flex-row align-items-center login-bg">
  <main class="c-main">
    <div class="container-fluid">
      <div class="col-md-9 mx-auto">
        <div class="card-group vh-75">
          <div class="card p-2 d-md-down-none border-0" style="background: #feffff;">
            <div class="card-body text-center">
              <div class="login-img-center">
                <img class="mb-2 img-fluid" src="assets/brand/auth-login.svg" alt="Info Tech" />
              </div>
            </div>
          </div>
          <div *ngIf="!Is2FA_BackupMethod" class="card p-5 border-0 shadow-none" style="background: #feffff;">
            <div class="card-body text-center mt-5">
              <h5 class="mb-4"><b>We've Sent a notification to your phone</b></h5>
              <h3 class="mb-4"><b>Accept it to authenticate your login</b></h3>
              <div class="code-auth mt-5 mb-4">
                <aside>{{TrustedDeviceName}}</aside>
              </div>
              <label class="d-none auth-check mt-5">Trust this device
                <input type="checkbox">
                <span class="checkmark"></span>
              </label>
              <div class="link-sec mt-5">
                <a class="t-link" (click)="UseBackupMethod(true)">Use a backup method instead</a>
                <a class="t-link d-none">Need help with multi-factor authentication?</a>
              </div>

              <div class="mt-4 mb-2 text-center">
                <div id="btnForgot" class="t-link" (click)="BackToLogin()">Back to login</div>
              </div>
            </div>
          </div>
          <div *ngIf="Is2FA_BackupMethod" class="card p-5 border-0 shadow-none" style="background: #feffff;">
            <form class="card-body text-center mt-5" [formGroup]="_2FA_OTP_Form" (ngSubmit)="On_2FA_OTP_Submit()"
              autocomplete="off">
              <h5 class="mb-4"><b>Enter the 6-digit OTP code found in your register mobile or email.</b></h5>
              <div class="code-auth mt-5 mb-4">
                <div class="input-group mb-4">
                  <input id="txtotp" type="text" numbers maxlength="6" class="form-control" placeholder="Enter OTP"
                    formControlName="otp" [ngClass]="{ 'is-invalid': submitted && f2fa.otp.errors }" autocomplete="off"
                    (keyup)="otpKeyUp($event);" />
                  <div *ngIf="submitted && f2fa.otp.errors" class="invalid-feedback">
                    <div>Enter the valid OTP.</div>
                  </div>
                </div>
              </div>
              <label class="d-none auth-check mt-5">Trust this device
                <input type="checkbox">
                <span class="checkmark"></span>
              </label>
              <div class="link-sec mt-5">
                <a class="t-link d-none">Need help with multi-factor authentication?</a>
              </div>
              <div class="row">
                <div class="mt-3 mb-2">
                  <a class="text-muted" *ngIf="ResendTimeoutSec > 0">
                    Resend OTP ({{ResendTimeoutSec}}s)
                  </a>
                  <a class="t-link" *ngIf="ResendTimeoutSec == 0" (click)="Resend(true)">
                    Resend OTP
                  </a>
                </div>
                <div class="col">
                  <button id="btn2FA_Submit" type="submit" [disabled]="loading" class="btn btn-block btn-primary px-4">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Submit
                  </button>
                </div>

                <div class="mt-4 mb-2 text-center">
                  <div id="btnForgot" class="t-link" (click)="BackToLogin()">Back to login</div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>


<style>
  input[type=password]::-ms-reveal,
  input[type=password]::-ms-clear
  {
      display: none;
  }
  .login-bg.v-new {
    background: none !important;
    background-size: cover;
  }

  .login-bg.v-new .card {
    border-radius: 25px;
    background-color: none;
  }

  .login-bg.v-new h5 {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    text-align: left;
    color: #344767;
  }

  .login-bg.v-new .login-form-center input {
    height: 48px;
    margin: 5px 0 0;
    padding: 12px 171px 13px 24px;
    border-radius: 24px !important;
    background-color: #fff !important;
    border: 1px solid#bdbdbd !important;
  }

  .login-bg.v-new label {
    font-size: 16px;
    font-weight: bold;
    width: 100%;
  }

  .login-bg.v-new label span {
    float: right;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #2f80ed;
    cursor: pointer;
  }

  .login-bg.v-new button {
    height: 48px;
    padding: 11px 154px 21px 301px;
    object-fit: contain;
    border-radius: 24px;
    background-color: #092c4c;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }

  .login-bg.v-new aside {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    text-align: left;
    color: #344767;
  }

  .login-bg.v-new aside span {
    display: block;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #344767;
    margin: 19px 0 0;
  }

  vh-100 {
    min-height: 100vh;
  }

  .login-bg.v-new .card-group {
    padding: 0px;
  }

  .login-bg.v-new .login-img-center {
    left: 0%;
    width: 100%;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .login-bg.v-new .login-form-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 50px;
    left: 10%;
    width: 80%;
  }

  .login-bg.v-new a.btn.learn-btn {
    height: 48px;
    border-radius: 24px;
    border: solid 1px #ffffff;
    background-color: rgba(9, 44, 76, 0);
    width: 192px;
    padding: 13px;
    float: left;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
  }



  .auth-fluid {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    min-height: 100vh;
    flex-direction: row;
    align-items: stretch;
    background-color: #fff;
    padding: 25px;
  }

  .auth-fluid-content-box {
    padding: 0px;
    max-width: 50%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    background: linear-gradient(to bottom, #2c446d 0%, #2c446d 70%, #000000 30%, #469dd4 30%, #469dd4 100%);
    position: relative;
  }

  .auth-fluid-form-box {
    padding: 2.5rem 3rem;
    max-width: 50%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }


  .auth-fluid-content-box h2 {
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.45;
    letter-spacing: normal;
    color: #f6b719;
  }

  .auth-fluid-content-box h3 {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
    color: #fff;
  }

  .auth-fluid-content-box p {
    color: #fff;
    font-weight: bold;
    margin: 0;
  }

  .auth-fluid-content-box svg {
    width: 100%;
    bottom: 25%;
    position: absolute;
    height: auto;
    transform: rotate(-180deg);
  }

  .login-bg.v-new .checkbox input {
    background: transparent;
    height: 14px !important;
    border-radius: 4px !important;
    margin: 0px !important;
    margin-top: 3px !important;
    padding: 7px;
    border: solid 1px #bdbdbd !important;
  }

  .login-bg.v-new .checkbox label {
    padding-left: 10px;
    color: #333;
  }

  .auth-fluid-form-box i {
    position: absolute;
    top: 62%;
    right: 4%;
    cursor: pointer;
    color: lightgray;
  }

  .auth-fluid-form-box i.icon-user {
    position: unset;
  }

  @media (min-width:1199px) and (max-width:1440px) {
    .login-bg.v-new h5 {
      font-size: 22px;
    }

    .login-bg.v-new .login-form-center input {
      height: 36px;
      padding: 10px;
    }

    .login-bg.v-new label {
      font-size: 12px;
    }

    .login-bg.v-new label span {
      float: right;
      font-size: 12px;
    }

    .login-bg.v-new button {
      height: 36px;
      padding: 5px 154px 21px 301px;
      font-size: 14px;
    }

    .login-bg.v-new aside {
      font-size: 22px;
    }

    .login-bg.v-new aside span {
      font-size: 16px;
    }

    .login-bg.v-new a.btn.learn-btn {
      height: 36px;
      padding: 7px;
      font-size: 12px;

    }
  }

  @media (min-width:991px) and (max-width:1199px) {
    .login-bg.v-new h5 {
      font-size: 18px;
    }

    .login-bg.v-new .login-form-center input {
      height: 36px;
      padding: 10px;
    }

    .login-bg.v-new label {
      font-size: 12px;
    }

    .login-bg.v-new label span {
      float: right;
      font-size: 12px;
    }

    .login-bg.v-new button {
      height: 36px;
      padding: 5px 154px 21px 301px;
      font-size: 14px;
    }

    .login-bg.v-new aside {
      font-size: 22px;
    }

    .login-bg.v-new aside span {
      font-size: 16px;
    }

    .login-bg.v-new a.btn.learn-btn {
      height: 36px;
      padding: 7px;
      font-size: 12px;

    }

    .auth-fluid {
      flex-direction: column;
    }

    .app-body {
      margin-top: 0px !important;
    }

    .auth-fluid-content-box {
      max-width: 100% !important;
      order: 2;
    }

    .auth-fluid-form-box {
      max-width: 100% !important;
    }

    .login-bg.v-new .login-img-center {
      position: relative;
      top: 50%;
      -ms-transform: unset;
      transform: unset;
      padding: 20px 0px;
    }

    .login-bg.v-new .login-form-center {
      position: unset;
      top: 50%;
      -ms-transform: unset;
      transform: unset;
      padding: 0px;
      width: 70%;
      margin: auto;
    }
  }




  @media only screen and (max-width: 991px) {
    .login-bg.v-new h5 {
      font-size: 18px;
    }

    .login-bg.v-new .login-form-center input {
      height: 36px;
      padding: 10px;
    }

    .login-bg.v-new label {
      font-size: 12px;
    }

    .login-bg.v-new label span {
      float: right;
      font-size: 12px;
    }

    .login-bg.v-new button {
      height: 36px;
      padding: 5px 154px 21px 301px;
      font-size: 14px;
    }

    .login-bg.v-new aside {
      font-size: 22px;
    }

    .login-bg.v-new aside span {
      font-size: 16px;
    }

    .login-bg.v-new a.btn.learn-btn {
      height: 36px;
      padding: 7px;
      font-size: 12px;

    }

    .auth-fluid {
      flex-direction: column;
    }

    .app-body {
      margin-top: 0px !important;
    }

    .auth-fluid-content-box {
      max-width: 100% !important;
      order: 2;
    }

    .auth-fluid-form-box {
      max-width: 100% !important;
    }

    .login-bg.v-new .login-img-center {
      position: relative;
      top: 50%;
      -ms-transform: unset;
      transform: unset;
      padding: 20px 0px;
    }

    .login-bg.v-new .login-form-center {
      position: unset;
      top: 50%;
      -ms-transform: unset;
      transform: unset;
      padding: 0px;
      width: 70%;
      margin: auto;
    }
  }

  @media only screen and (max-width: 768px) {
    .login-bg.v-new .login-form-center {
      position: unset;
      top: 50%;
      -ms-transform: unset;
      transform: unset;
      padding: 0px;
      width: 100%;
      margin: auto;
    }

    .auth-fluid-form-box {
      padding: 2.5rem 0rem;
    }

    .auth-fluid {
      padding: 10px;
    }

    .auth-fluid-content-box h2 {
      font-size: 30px;
    }
  }

  @media only screen and (max-width: 567px) {
    .auth-fluid-content-box h2 {
      font-size: 22px;
    }

    .auth-fluid-content-box h3 {
      font-size: 12px;
    }

    .auth-fluid-content-box p {
      font-size: 10px;
    }
  }
</style>