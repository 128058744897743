import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { AlertMessage } from '../../../utilities/_helpers/alert.message';
import { HttpServices } from '../../../utilities/_services/http.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PrintService } from '../../template/services/print.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-emailattachment',
  templateUrl: './emailattachment.component.html',
  styleUrls: ['./emailattachment.component.scss']
})
export class EmailattachmentComponent implements OnInit {
  @Input() transID: any;
  @Input() transTypeID: any;
  @Input() templateId: any;
  @Input() templateType: string;
  @Input() templateGroupId: number;
  @Input() IsNewViewScreen: boolean;

  @ViewChild('popOver') public popover: NgbPopover;
  fileAttachments: any = [];
  errorList: any = [];
  @ViewChild('fileUploader') fileUploader: ElementRef;
  progressBar: boolean = false;
  errorNotifierId: number = 0;
  lstEmailAttachments: any = [];
  usedAttachmentSize: string = '0 MB';
  attachmentSize: number = 0;
  constructor(private http: HttpServices,
    private alertMessage: AlertMessage,
    private httpClient: HttpClient,
    public printService: PrintService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    //this.getFiles();
  }

  CloseDialog() {
    this.popover.close();
  }

  getFiles() {
    this.fileAttachments = [];
    this.lstEmailAttachments = [];
    let apiUrl = environment.organizationApiUrl + '/Document/GetFiles/' + this.transID + '/' + this.transTypeID;
    this.http.getservice(apiUrl).subscribe({
      next: (data) => {
        let lsteAttachment: any = data['responseData'];
        lsteAttachment.forEach(e => {
          let obj = {
            createdByName: e.createdByName,
            createdDate: e.createdDate,
            filbytes: e.filbytes,
            contentLength: e.contentLength,
            fileSize: this.getFileSize(e.contentLength, 'F'),
            fileExtension: e.fileName.split('.')[1],
            fileType: null,
            fileGroup: e.fileGroup,
            fileGroupID: e.fileGroupID,
            fileGroupName: e.fileGroupName,
            fileID: e.fileID,
            fileName: e.fileName,
            filePath: e.filePath,
            transactionID: e.transactionID,
            transactionTypeID: e.transactionTypeID,
            updatedByName: e.updatedByName,
            updatedDate: e.updatedDate,
            isAttached: false
          }
          this.fileAttachments.push(obj);
        });
      }
    })
  }

  onFileChange(event: any) {
    let allowedExtn = ['.doc', '.docx', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/*', '.pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', '.txt', '.xls', 'image/png', 'image/jpg', 'image/jpeg', '.xlsx', 'application/pdf', '.msg', 'text/plain', 'message/rfc822', 'image/gif', 'application/vnd.ms-outlook'];
    let filesCount = 0;
    if (!!event.target.files) {
      filesCount = event.target.files.length;
      for (var i = 0; i < event.target.files.length; i++) {
        let file = event.target.files[i];
        if (allowedExtn.includes(file.type)) {
          if (file.size <= 5000000) {
            this.progressBar = true;
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[i]);
            reader.onload = (event: any) => {
              let obj = {
                createdByName: undefined,
                createdDate: undefined,
                filbytes: reader.result as string,
                contentLength: file.size,
                fileSize: this.getFileSize(file.size, 'F'),
                fileExtension: file.name.split('.')[1],
                fileType: file.type,
                fileGroup: null,
                fileGroupID: null,
                fileGroupName: null,
                fileID: 0,
                fileName: file.name,
                filePath: null,
                transactionID: this.transID,
                transactionTypeID: this.transTypeID,
                updatedByName: null,
                updatedDate: null,
                isAttached: true
              }
              this.fileAttachments.push(obj);
              setTimeout(() => {
                this.progressBar = false;
                if (!!this.fileUploader)
                  this.fileUploader.nativeElement.value = null;
              }, 500);
              this.selectFile();
            }
          }
          else {
            if (!!this.fileUploader)
              this.fileUploader.nativeElement.value = null;

            filesCount = filesCount - 1;
            let fileSize = file.name + ' - ' + this.translate.instant('SharedModule.File size should be maximum 5MB.') +' </br>';
            this.errorList.push(fileSize);
          }
        }
        else {
          let fileSize = file.name + ' - ' + this.translate.instant('SharedModule.File extension is not allowed.') + ' </br>';
          this.errorList.push(fileSize);
        }
      }
      if (this.errorList.length > 0) {
        this.errorNotifierId = this.alertMessage.errorNotifier(this.errorList, this.errorNotifierId);
        this.errorList = [];
        if (!!this.fileUploader)
          this.fileUploader.nativeElement.value = null;
      }
    }
  }

  deleteFile(index) {
    this.fileAttachments.splice(index, 1);
    this.selectFile();
  }

  selectFile() {
    if (this.transTypeID == 11 || this.transTypeID == 10) {
      this.alertMessage.clearNotifier();
      this.lstEmailAttachments = [];
      let contentLength = 0;
      this.fileAttachments.forEach(e => {
        if (e.isAttached == true) {
          let obj = {
            fileID: e.fileID,
            fileName: e.fileName,
            filebytes: e.filbytes,
            fileExtension: e.fileExtension,
            fileType: e.fileType,
            filePath: e.filePath,
            transactionID: this.transID,
            transactionTypeID: this.transTypeID,
            isCanDelete: e.fileID > 0 ? false : true,
          }
          this.lstEmailAttachments.push(obj);
          contentLength = contentLength + e.contentLength;
        }
      });

      this.usedAttachmentSize = this.getFileSize(contentLength, 'T');
      if (this.attachmentSize > 10) {
        let msg = this.translate.instant('SharedModule.Attachments total size per mail allowed maximum 10MB only. Please remove some files.');
        this.errorNotifierId = this.alertMessage.warningNotifier(msg, this.errorNotifierId);
      }
    } else {
      this.alertMessage.clearNotifier();
      this.lstEmailAttachments = [];
      let contentLength = 0;
      this.fileAttachments.forEach(e => {
        if (e.isAttached == true) {
          let obj = {
            fileID: e.fileID,
            fileName: e.fileName,
            filebytes: e.filbytes,
            fileExtension: e.fileName.split('.')[1],
            fileType: e.fileType,
            filePath: e.filePath,
            transactionID: e.transactionID,
            transactionTypeID: e.transactionTypeID,
            isCanDelete: e.fileID > 0 ? false : true,
          }
          this.lstEmailAttachments.push(obj);
          contentLength = contentLength + e.contentLength;
        }
      });

      this.usedAttachmentSize = this.getFileSize(contentLength, 'T');
      if (this.attachmentSize > 10) {
        let msg = this.translate.instant('SharedModule.Attachments total size per mail allowed maximum 10MB only. Please remove some files.');
        this.errorNotifierId = this.alertMessage.warningNotifier(msg, this.errorNotifierId);
      }
    }
  }

  getFileSize(bytes, callFrom) {
    this.attachmentSize = 0;
    let size = '';
    let mbcheck: any = 0;
    if (bytes > 0) {
      let kb = bytes / 1024;
      let mb = kb / 1024;
      mbcheck = mb;
      let gb = mb / 1024;

      if (kb < 1024) {
        size = (Math.round(kb * 100) / 100).toString() + ' KB';
      }
      else if (mb < 1024) {
        size = (Math.round(mb * 100) / 100).toString() + ' MB';
      }
      else {
        size = (Math.round(gb * 100) / 100).toString() + ' GB';
      }
    }

    if (callFrom == 'F' && size != '') {
      size = '(' + size + ')'
    }
    else if (mbcheck > 10 && callFrom == 'T') {
      this.attachmentSize = mbcheck;
    }
    return size;
  }

  getPaymentReceipt() {
    let isBatch = false;
    if (this.transTypeID == 24)
      isBatch = true;
    var templateReceiptParams = {
        GroupId: this.templateGroupId,
        TemplateId: this.templateId,
      PrintType: 2,
        TransIds: this.templateGroupId == 10 ? JSON.stringify([{
            ReceiveId: this.transID.toString(), TransTypeid: this.transTypeID
        }]) : this.transID.toString(),
      IsBatch: isBatch,
      TemplateType: this.templateType,
      IsNewViewScreen: this.IsNewViewScreen
    }
    let apiUrl = environment.templateApiUrl + "/Templates/GetReceipt";
    this.httpClient.post(apiUrl, templateReceiptParams).subscribe((response) => {
      this.fileAttachments = [];
      this.lstEmailAttachments = [];
      var file = response["responseData"];
      let obj = {
        filbytes: "data:application/pdf;base64," + file["fileContents"],
        // contentLength: this.getFileSize(file["fileLength"], 'T'),
        contentLength: file["fileLength"],
        fileSize: this.getFileSize(file["fileLength"], 'F'),
        fileExtension: 'pdf',
        fileName: file["fileName"],
        isAttached: true,
        fileID: 0,
        fileLength: file["fileLength"],
        fileType: file["contentType"],
      }
      this.fileAttachments.push(obj);
      let attachmentObj = {
        FileID: 0,
        FileName: file["fileName"],
        Filebytes: "data:application/pdf;base64," + file["fileContents"],
        ContentLength: file["fileLength"],
        FileType: file["contentType"],
        FileExtension: 'pdf',
        transactionID: this.transID,
        transactionTypeID: this.transTypeID
      }
      this.lstEmailAttachments.push(attachmentObj);
      this.usedAttachmentSize = this.getFileSize(file["fileLength"], 'T');
      if (this.attachmentSize > 10) {
        let msg = this.translate.instant('SharedModule.Attachments total size per mail allowed maximum 10MB only. Please remove some files.');
        this.errorNotifierId = this.alertMessage.warningNotifier(msg, this.errorNotifierId);
      }
    });
  }

  getPaymentVoucher() {
    let isBatch = false;
    if (this.transTypeID == 23)
      isBatch = true;

    var templateReceiptParams = {
        GroupId: this.templateGroupId,
        TemplateId: this.templateId,
      PrintType: 2,
        TransIds: this.templateGroupId == 11? JSON.stringify([{
            SpendId: this.transID.toString(), TransTypeid: this.transTypeID
        }]) : this.transID.toString(),
      IsBatch: isBatch,
      TemplateType: this.templateType,
      IsNewViewScreen: this.IsNewViewScreen
    }
    let apiUrl = environment.templateApiUrl + "/Templates/GetVoucher";
    this.httpClient.post(apiUrl, templateReceiptParams).subscribe((response) => {
      this.fileAttachments = [];
      this.lstEmailAttachments = [];
      var file = response["responseData"];
      let obj = {
        filbytes: "data:application/pdf;base64," + file["fileContents"],
        // contentLength: this.getFileSize(file["fileLength"], 'T'),
        contentLength: file["fileLength"],
        fileSize: this.getFileSize(file["fileLength"], 'F'),
        fileExtension: 'pdf',
        fileName: file["fileName"],
        isAttached: true,
        fileID: 0,
        fileType: file["contentType"],
      }
      this.fileAttachments.push(obj);
      let attachmentObj = {
        FileID: 0,
        FileName: file["fileName"],
        Filebytes: "data:application/pdf;base64," + file["fileContents"],
        ContentLength: file["fileLength"],
        FileType: file["contentType"],
        FileExtension: 'pdf',
        transactionID: this.transID,
        transactionTypeID: this.transTypeID
      }
      this.lstEmailAttachments.push(attachmentObj);
      this.usedAttachmentSize = this.getFileSize(file["fileLength"], 'T');
      if (this.attachmentSize > 10) {
        let msg = this.translate.instant('SharedModule.Attachments total size per mail allowed maximum 10MB only. Please remove some files.');
        this.errorNotifierId = this.alertMessage.warningNotifier(msg, this.errorNotifierId);
      }
    });
  }
}
